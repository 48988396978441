<template>
  
  <div>
       <div class="header">
             <ul class="left_menu">
                <div class="item"> 
               
                     <li class="active a">职位管理</li>
                       <li class=" a"><router-link to='/index/cwzj'> 财务总监</router-link></li>
                       
                </div>
                 <div  class="item">  
                  <li class="a" ><router-link to="/index/xiaoxi" style="display:flex;    align-items: center">  <i class=" el-icon-message-solid" style="color:#0034FF;width:25px;height:25px;border-radius:100%;background: #f5f5f5;text-align:center;line-height:25px;margin-right:5px"></i> <p style="width:15px;height:15px;background:red;text-align:center;font-size:12px;line-height:15px;border-radius:100%;color:#fff;margin-left:-10px;margin-top:-3px" v-if='result !==0' >{{result}}</p> 消息通知</router-link> </li>
                     <li style="cursor:pointer;" @click="dialogFormVisible = true">总台管理<i class="el-icon-caret-bottom"></i>  </li>
                </div>    

                                        <el-dialog  class="kk" title="个人信息" style="text-align:center" :visible.sync="dialogFormVisible">
                        <el-form :model="info">
                            <el-form-item label="昵称" :label-width="formLabelWidth">
                            <el-input v-model="info.name" autocomplete="off">  <el-button slot="append" @click="a11()">修改</el-button></el-input>
                         
                            </el-form-item>
                            <el-form-item label="登录账号" :label-width="formLabelWidth">
                             <el-input v-model="info.phone" autocomplete="off"  :disabled="true"></el-input>
                            </el-form-item>
                            <el-form-item label="登录密码" :label-width="formLabelWidth">
                             <el-input v-model="password3" placeholder="输入旧密码"  autocomplete="off">  <el-button slot="append"  @click="a22()">修改</el-button></el-input>
                            </el-form-item>
                            <el-form-item label="新密码" v-show="password2" :label-width="formLabelWidth">
                             <el-input v-model="password" autocomplete="off">  <el-button slot="append"  @click="a33()">确认</el-button> </el-input>
                            </el-form-item>
                        </el-form>
                        <div slot="footer" class="dialog-footer">
                            <el-button type="primary" @click="tuichu" style="width:100%">退出登录</el-button>
                        </div>
                        </el-dialog>  
             </ul>
        </div>
        <p style="font-size: 22px;

font-weight: 600;
color: #000000;
line-height: 30px;margin:20px 40px">角色管理 <span style="font-size: 14px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #000000;
line-height: 20px;">服务于业务发展的各个角色</span></p>

       <div class="change" style="padding:15px 40px;background:#f5f5f5">
        <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
            <el-tab-pane label="市场总监" name="first">
            <div style="display:flex" class="list"> <p @click="show1" :class="{'actives':key1==0}">列表</p>
            <p @click="show2" :class="{'actives':key1==1}">业务统计</p></div>   
   
      <div class="content" v-show="key1==0" style="padding:0;background:#f5f5f5">
             
                        <div style="padding:10px;background:#fff">
                                <div style="display:flex;    justify-content: space-between;align-items: center;
                                margin-bottom: 15px;">
                                <p style="font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #000000;
                line-height: 22px;">市场总监</p>
                            <div>        <el-button   type="primary"  size="mini" @click="exportOrderData()">  <i class="el-icon-download"></i>  导出</el-button>  <el-button
                        size="mini"
                         type="warning"
                        @click="dialogFormVisible1 = true">创建账号</el-button>

                      </div>
                     

                                <el-dialog title="创建市场总监" :visible.sync="dialogFormVisible1" class="cj">
                                <el-form :model="form1">
                                    <el-form-item label="姓名" :label-width="formLabelWidth">
                                    <el-input v-model="form1.name" autocomplete="off"></el-input>
                                    </el-form-item>
                                    <el-form-item label="电话" :label-width="formLabelWidth">
                                    <el-input v-model="form1.phone" autocomplete="off"></el-input>
                                    </el-form-item>
                                       <el-form-item label="身份证号码" :label-width="formLabelWidth">
                    <el-input v-model="form1.id" autocomplete="off"></el-input>
                    </el-form-item>
  <el-form-item label="入职时间" :label-width="formLabelWidth">
                                 
                                     <el-date-picker
                                            v-model="form1.worktime"
                                                             format="yyyy 年 MM 月"
                                                                  value-format="yyyy-MM"
                                            type="month"
                                            placeholder="选择月">
                                            </el-date-picker>
                                    </el-form-item>
   
   <div style="display:flex;justify-content: space-between;text-align:center;padding:0 50PX">
       <div style="    position: relative;">
           <p style="margin:20px 0">身份证正面</p>
          
          <img :src="producImg"   style=" margin:10px 0; 
    color: #8c939d;
    width: 178px;
    height: 178px;
      background-color: #fbfdff;
    
    border-radius: 6px;
    text-align: center;">
    <i class="el-icon-plus" style="position: absolute;top:148px;left:86px"></i>
          <input s type="file" name="avatar" style=" margin:10px 0;position: absolute;
width:178px;
height:178px;
right: 0;
top: 60px;
opacity: 0;
filter: alpha(opacity=0);
cursor: pointer " id="uppic" accept="image/gif,image/jpeg,image/jpg,image/png" @change="changeImage" ref="avatarInput"  class="uppic">
       </div>
       <div style="    position: relative;">
           <p style="margin:20px 0">身份证反面</p>
       
          <img :src="producImg1"  style=" margin:10px 0;   font-size: 1px;
    color: #8c939d;
    width: 178px;
    height: 178px;
     background-color: #fbfdff;
    
    border-radius: 6px;
    text-align: center;" >
      <i class="el-icon-plus" style="position: absolute;top:148px;left:86px"></i>
          <input type="file" name="avatar" style="margin:10px 0;position: absolute;
width:178px;
height:178px;
right: 0;
top: 60px;
opacity: 0;
filter: alpha(opacity=0);
cursor: pointer  " id="uppic" accept="image/gif,image/jpeg,image/jpg,image/png" @change="changeImage1" ref="avatarInput1"  class="uppic">
       </div>

                      
   </div>
                                      <el-form-item label="平台证件编号" :label-width="formLabelWidth">
                                    <el-input v-model="form1.key_2" autocomplete="off"></el-input>
                                    </el-form-item>
                                    <el-form-item label="区域选择" :label-width="formLabelWidth">
                                    <el-cascader  :props="props" :options="options"   @change="changeProvinceCity">
                                        <template slot-scope="{ node, data }">
                                            <span>{{ data.label }}</span>
                                        
                                            <span v-if="!node.isLeaf"> ({{ data.children.length }}) </span>
                                        </template>
                                        </el-cascader>
                                    </el-form-item>
                                </el-form>
                                <div slot="footer" class="dialog-footer">
                            
                                    <el-button type="primary" @click="tianjia1" style="width:100%">添加</el-button>
                                </div>
                                </el-dialog>
                </div>


                  <div class="search" style="padding:20px 0px; display:flex;font-size:14px;justify-content: space-between;align-items: center;border-top:1px solid #ccc">
            <div style=" display:flex;">
              

               <div class="demo-input-suffix" style="margin-right:15px">
              <span style="margin-right:5px">姓名</span>  
                <el-input style="width:150px;height:30px"
                    placeholder="请输入"
                    size="small"
                    v-model="input2">
                </el-input>
            </div>

            <div class="demo-input-suffix" style="margin-right:15px">
              <span style="margin-right:5px">手机</span>  
                <el-input style="width:150px;height:30px"
                    placeholder="请输入"
                    size="small"
                    v-model="input3">
                </el-input>
            </div>

           <div class="demo-input-suffix" style="margin-right:15px">
              <span style="margin-right:5px">业务区域</span>  
                <el-cascader  :props="props" :options="options"   @change="changeProvinceCity"   size="small">
                    <template slot-scope="{ node, data }">
                        <span>{{ data.label }}</span>
                         <span v-if="!node.isLeaf"> ({{ data.children.length }}) </span>
                    </template>
                </el-cascader>
            </div>
            </div>
            <div style="display:flex" class="l">
                <p style="width: 80px;
height: 28px;line-height:28px;cursor: pointer;background:red;color:#fff;border-radius:3.5px;
border: 1px solid #DFE0E8;margin-right:20px;text-align:center" @click="ck1">重置</p> <p style="width: 80px;
height: 28px;line-height:28px;text-align:center;cursor: pointer;background:rgb(128, 181, 71);color:#fff;border-radius:3.5px;
border: 1px solid #DFE0E8;" @click="ck2">查询</p>
            </div>
        </div>
                                <el-table
                                  height="400"
                    :border="true"
                    :data="tableData"
                :header-cell-style="{background:'#FAFAFA'}"
                    style="width: 100%"
                 id="out-table"
                    >
                   <el-table-column
                    :show-overflow-tooltip="true" 
                
                    prop="key_2"
                    label="平台证件编号"
                    >
                    </el-table-column>
                
                    <el-table-column
                    :show-overflow-tooltip="true" 
                
                    prop="jobnumber"
                    label="ID"
                    >
                    </el-table-column>
                    <el-table-column
                    label="姓名"
                    prop="name" 
                    >
                    
                    </el-table-column>

                    

                    <el-table-column
                    label="手机号"
                    prop="phone" 
                    
                    >

                    
                    
                    </el-table-column>
                    <el-table-column
                        prop="createtime" 
                    label="最近登录时间" 
                        :show-overflow-tooltip="true" 
                    >
                    </el-table-column>
                    <el-table-column     
                    label="用户状态" 
                        :show-overflow-tooltip="true" 
                    >
                    <template slot-scope="scope" >
                    <p :class="{red:scope.row.isactive!==0}"> {{scope.row.isactive | capitalize}}</p>
                    </template>
                    </el-table-column>


                       <el-table-column
                    
                    label="查看服务区域"
                >
                    <template slot-scope="scope">

                        
                        <el-button
                        size="mini"
                            type="primary"
                          
                        @click="find(scope.$index, scope.row)">查看</el-button>
                            

                                       <el-dialog
                                        title="服务区域"
                                        :visible.sync="dialogVisiblefind1"
                                     
                                        class="he"
                                        style="text-align:left"
                                       >
                                        <h2 style="font-size:18px;font-weight:600;margin:10px 0">省份:</h2>
                                        <div class="a" style="  width: 100%;
     display: flex;
     flex-wrap: wrap;">
                                              <p class="" v-for="(item,index) in find1" :key="index" style="width:25%;flex: 1;min-width: 25%; max-width: 25%">
                                           {{item}}
                                        </p>
                                        </div>
                                         <h2 style="font-size:18px;font-weight:600;margin:10px 0">城市:</h2>
                                        <div class="a" style="  width: 100%;
     display: flex;
     flex-wrap: wrap;">
                                              <p class="" v-for="(item,index) in find2" :key="index" style="width:25%;flex: 1;min-width: 25%; max-width: 25%">
                                           {{item}}
                                        </p>
                                        </div>
                                           <h2 style="font-size:18px;font-weight:600;margin:10px 0">地区:</h2>
                                        <div class="a" style="  width: 100%;
     display: flex;
     flex-wrap: wrap;">
                                              <p class="" v-for="(item,index) in find3" :key="index" style="width:25%;flex: 1;min-width: 25%; max-width: 25%">
                                           {{item}}
                                        </p>
                                        </div>
                                        <!-- <p>省份</p>
                                         <div class="" v-for="(item,index) in find2" :key="index">
                                            <p>{{item}}</p> 
                                        </div>
                                        <p>省份</p>
                                      <div class="" v-for="(item,index) in find3" :key="index">
                                            <p>{{item}}</p> 
                                        </div> -->

                                        </el-dialog>
                        
                    </template>
                    </el-table-column>

                     <el-table-column
                    
                    label="操作"
                >
                    <template slot-scope="scope">

                        
                        <el-button
                        size="mini"
                            type="warning"
                            v-if='scope.row.isactive==0'
                        @click="addarea(scope.$index, scope.row)">增加区域</el-button>
                        

                                       <el-dialog
                                        title="增加区域"
                                        :visible.sync="add"

                                        style="text-align:center"
                                       >
                                  <div style=" display:block:margin:20px auto">
                                        <el-cascader  :props="props" :options="options"   @change="changeProvinceCity"   size="small">
                                                <template slot-scope="{ node, data }">
                                                    <span>{{ data.label }}</span>
                                                    <span v-if="!node.isLeaf"> ({{ data.children.length }}) </span>
                                                </template>
                                            </el-cascader>
                                  </div>
                                      
                                    <div style=" display:block:margin-top:20px"> <el-button
                                            size="mini"
                                                type="warning"
                                                style="margin:20px "
                                            @click="adds()">确定增加</el-button></div>
                                            
                                       </el-dialog>

                         
                    </template>
                    </el-table-column>

                <el-table-column
                    
                    label="操作"
                >
                    <template slot-scope="scope">

                        
                        <el-button
                        size="mini"
                            type="warning"
                            v-if='scope.row.isactive==0'
                        @click="handleEdit(scope.$index, scope.row)">禁用账号</el-button>
                            <el-button
                                v-if='scope.row.isactive!==0'
                        size="mini"
                            type='info'
                        @click="handleEdit1(scope.$index, scope.row)">恢复账号</el-button>
                        
                    </template>
                    </el-table-column>



                      <el-table-column
                    
                    label="删除账号"
                >
                    <template slot-scope="scope">

                        
                        <el-button
                        size="mini"
                            type="danger"
                           
                        @click="sc(scope.$index, scope.row)">删除账号</el-button>
                           
                        
                    </template>
                    </el-table-column>
                </el-table>
    <el-row :gutter="24">
  <el-col :span="6" :offset="13">      <div class="block">
                    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagination.page_index"
      :page-sizes="pagination.page_sizes"
      :page-size="pagination.page_size"
      :layout="pagination.layout"
      :total='pagination.total'
      style="margin-top:20px">
    </el-pagination>
                </div></el-col>

</el-row>
           
           </div>
                      



            </div>

               <div class="content"  v-show="key1==1" style="padding:0;background:#f5f5f5">
             
                        <div style="padding:10px;background:#fff">
                                <div style="display:flex;    justify-content: space-between;align-items: center;
                                margin-bottom: 15px;">
                                <p style="font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #000000;
                line-height: 22px;">市场总监 | 统计 </p>
                          
 <el-button   type="primary"  size="mini" @click="exportOrderData1()"> <i class="el-icon-download"></i>导出</el-button>
                        

                          
                </div>

           <!-- <div class="search" style="padding:20px 0px; display:flex;font-size:14px;justify-content: space-between;align-items: center;border-top:1px solid #ccc">
            <div style=" display:flex;">
              

         
            <div class="demo-input-suffix" style="margin-right:15px">
              <span style="margin-right:5px">手机</span>  
                <el-input style="width:150px;height:30px"
                    placeholder="请输入"
                    size="small"
                    v-model="yw12">
                </el-input>
            </div>
         

         
            </div>
            <div style="display:flex" class="l">
                <p style="width: 80px;
height: 28px;line-height:28px;cursor: pointer;background:red;color:#fff;border-radius:3.5px;
border: 1px solid #DFE0E8;margin-right:20px;text-align:center" @click="cz">重置</p> <p style="width: 80px;
height: 28px;line-height:28px;text-align:center;cursor: pointer;background:rgb(128, 181, 71);color:#fff;border-radius:3.5px;
border: 1px solid #DFE0E8;" @click="cx">查询</p>
            </div>
        </div> -->
                    <el-table
      :border="true"
    :data="tb1"
   :header-cell-style="{background:'#FAFAFA'}"
    style="width: 100%"
        id="out-table1"
        height='450'
    >
   
   
     <el-table-column
     :show-overflow-tooltip="true" 
   
      prop="jobnumber"
      label="平台证件编码"
     >
    </el-table-column>
    <el-table-column
      label="姓名"
      prop="name" 
     >
    
    </el-table-column>

       <el-table-column
      label="手机号"
    prop="phone" 
      
    >
    
       </el-table-column>
   
         <el-table-column
      label="业务经理数"
    prop="ywjlsl" 
      
    >
    
       </el-table-column>

         <el-table-column
      label="代理商数量"
     prop="dlssl" 
      
    >
    
       </el-table-column>
         <el-table-column
      label="琴行数量"
     prop="qhsl" 
      
    >
    
       </el-table-column>
                <el-table-column
      label="会员数量"
    prop="hysl" 
      
    >
    
       </el-table-column>
                <el-table-column
      label="订单数量"
    prop="ordersl" 
      
    >
    
       </el-table-column>
  </el-table>
 <el-row :gutter="24">
  <el-col :span="6" :offset="13">      <div class="block">
                    <el-pagination
      @size-change="handleSizeChange11"
      @current-change="handleCurrentChange11"
      :current-page="pagination22.page_index"
      :page-sizes="pagination22.page_sizes"
      :page-size="pagination22.page_size"
      :layout="pagination22.layout"
      :total='pagination22.total'
      style="margin-top:20px">
    </el-pagination>
                </div></el-col>

</el-row>

                        
           </div>
                      



            </div>

            </el-tab-pane>
            <el-tab-pane label="市场 | 业务经理" name="second">
                    <!-- <div style="display:flex" class="list"> <p @click="show1" :class="{'actives':key1==0}">列表</p>
                    <p @click="show22" :class="{'actives':key1==1}">业务统计</p></div>    -->
   
      <div class="content" style="padding:0;background:#f5f5f5">
             
                        <div style="padding:10px;background:#fff">
                                <div style="display:flex;    justify-content: space-between;align-items: center;
                                margin-bottom: 15px;">
                                <p style="font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #000000;
                line-height: 22px;">市场业务经理</p>
                            <!-- <el-button
                        size="mini"
                        
                        @click="dialogFormVisible11 = true">创建账号</el-button> -->

                         <el-button   type="primary"  size="mini" @click="exportOrderData2()"> <i class="el-icon-download"></i>导出</el-button>

                                 <el-dialog title="创建市场业务经理" :visible.sync="dialogFormVisible11">
                                <el-form :model="form2">
                                    <el-form-item label="姓名" :label-width="formLabelWidth">
                                    <el-input v-model="form2.name" autocomplete="off"></el-input>
                                    </el-form-item>
                                    <el-form-item label="电话" :label-width="formLabelWidth">
                                    <el-input v-model="form1.phone" autocomplete="off"></el-input>
                                    </el-form-item>
                                    <el-form-item label="区域选择" :label-width="formLabelWidth">
                                    <el-cascader  :props="props" :options="options"   @change="changeProvinceCity">
                                        <template slot-scope="{ node, data }">
                                            <span>{{ data.label }}</span>
                                        
                                            <span v-if="!node.isLeaf"> ({{ data.children.length }}) </span>
                                        </template>
                                        </el-cascader>
                                    </el-form-item>
                                </el-form>
                                <div slot="footer" class="dialog-footer">
                            
                                    <el-button type="primary" @click="tianjia2" style="width:100%">添加</el-button>
                                </div>
                                </el-dialog>
                </div>

                     <div class="search" style="padding:20px 0px; display:flex;font-size:14px;justify-content: space-between;align-items: center;border-top:1px solid #ccc">
            <div style=" display:flex;">
              

               <div class="demo-input-suffix" style="margin-right:15px">
              <span style="margin-right:5px">姓名</span>  
                <el-input style="width:150px;height:30px"
                    placeholder="请输入"
                    size="small"
                    v-model="input4">
                </el-input>
            </div>

            <div class="demo-input-suffix" style="margin-right:15px">
              <span style="margin-right:5px">手机</span>  
                <el-input style="width:150px;height:30px"
                    placeholder="请输入"
                    size="small"
                    v-model="input5">
                </el-input>
            </div>

           <div class="demo-input-suffix" style="margin-right:15px">
              <span style="margin-right:5px">业务区域</span>  
                <el-cascader  :props="props" :options="options"   @change="changeProvinceCity"   size="small">
                    <template slot-scope="{ node, data }">
                        <span>{{ data.label }}</span>
                         <span v-if="!node.isLeaf"> ({{ data.children.length }}) </span>
                    </template>
                </el-cascader>
            </div>
            </div>
            <div style="display:flex" class="l">
                <p style="width: 80px;
height: 28px;line-height:28px;cursor: pointer;background:red;color:#fff;border-radius:3.5px;
border: 1px solid #DFE0E8;margin-right:20px;text-align:center" @click="ck5">重置</p> <p style="width: 80px;
height: 28px;line-height:28px;text-align:center;cursor: pointer;background:rgb(128, 181, 71);color:#fff;border-radius:3.5px;
border: 1px solid #DFE0E8;" @click="ck6">查询</p>
            </div>
        </div>
                                <el-table
                    :border="true"
                    :data="tableData"
                :header-cell-style="{background:'#FAFAFA'}"
                    style="width: 100%"
                 id="out-table2"
                 height='350'
                    >
                  <el-table-column
                    :show-overflow-tooltip="true" 
                
                    prop="key_2"
                    label="平台证件编号"
                    >
                    </el-table-column>
                
                
                    <el-table-column
                    :show-overflow-tooltip="true" 
                
                    prop="id"
                    label="ID"
                    >
                    </el-table-column>
                    <el-table-column
                    label="姓名"
                    prop="name" 
                    >
                    
                    </el-table-column>

                    <el-table-column
                    label="手机号"
                    prop="phone" 
                    
                    >
                    
                    </el-table-column>
                    <el-table-column
                        prop="createtime" 
                    label="最近登录时间" 
                        :show-overflow-tooltip="true" 
                    >
                    </el-table-column>
                    <el-table-column     
                    label="用户状态" 
                        :show-overflow-tooltip="true" 
                    >
                    <template slot-scope="scope" >
                    <p :class="{red:scope.row.isactive!==0}"> {{scope.row.isactive | capitalize}}</p>
                    </template>
                    </el-table-column>

    <el-table-column
                    
                    label="查看服务区域"
                >
                    <template slot-scope="scope">

                        
                        <el-button
                        size="mini"
                            type="primary"
                          
                        @click="finda(scope.$index, scope.row)">查看</el-button>
                            

                                         <el-dialog
                                        title="服务区域"
                                        :visible.sync="dialogVisiblefind1a"
                                     
                                        class="he"
                                        style="text-align:left"
                                       >
                                        <h2 style="font-size:18px;font-weight:600;margin:10px 0">省份:</h2>
                                        <div class="a" style="  width: 100%;
     display: flex;
     flex-wrap: wrap;">
                                              <p class="" v-for="(item,index) in find111" :key="index" style="width:25%;flex: 1;min-width: 25%; max-width: 25%">
                                           {{item}}
                                        </p>
                                        </div>
                                         <h2 style="font-size:18px;font-weight:600;margin:10px 0">城市:</h2>
                                        <div class="a" style="  width: 100%;
     display: flex;
     flex-wrap: wrap;">
                                              <p class="" v-for="(item,index) in find222" :key="index" style="width:25%;flex: 1;min-width: 25%; max-width: 25%">
                                           {{item}}
                                        </p>
                                        </div>
                                           <h2 style="font-size:18px;font-weight:600;margin:10px 0">地区:</h2>
                                        <div class="a" style="  width: 100%;
     display: flex;
     flex-wrap: wrap;">
                                              <p class="" v-for="(item,index) in find333" :key="index" style="width:25%;flex: 1;min-width: 25%; max-width: 25%">
                                           {{item}}
                                        </p>
                                        </div>
                                        <!-- <p>省份</p>
                                         <div class="" v-for="(item,index) in find2" :key="index">
                                            <p>{{item}}</p> 
                                        </div>
                                        <p>省份</p>
                                      <div class="" v-for="(item,index) in find3" :key="index">
                                            <p>{{item}}</p> 
                                        </div> -->

                                        </el-dialog>
                        
                    </template>
                    </el-table-column>
                    

                <el-table-column
                    
                    label="操作"
                >
                    <template slot-scope="scope">

                        
                        <el-button
                        size="mini"
                            type="danger"
                            v-if='scope.row.isactive==0'
                        @click="handleEdit2(scope.$index, scope.row)">禁用账号</el-button>
                            <el-button
                                v-if='scope.row.isactive!==0'
                        size="mini"
                            type='info'
                        @click="handleEdit3(scope.$index, scope.row)">恢复账号</el-button>
                        
                    </template>
                    </el-table-column>
                     <el-table-column
                    
                    label="删除账号"
                >
                    <template slot-scope="scope">

                        
                        <el-button
                        size="mini"
                            type="danger"
                           
                        @click="sc1(scope.$index, scope.row)">删除账号</el-button>
                           
                        
                    </template>
                    </el-table-column>
                </el-table>

                   <el-row :gutter="24">
  <el-col :span="6" :offset="13">      <div class="block">
                    <el-pagination
      @size-change="handleSizeChange2"
      @current-change="handleCurrentChange2"
      :current-page="pagination3.page_index"
      :page-sizes="pagination3.page_sizes"
      :page-size="pagination3.page_size"
      :layout="pagination3.layout"
      :total='pagination3.total'
      style="margin-top:20px">
    </el-pagination>
                </div></el-col>

</el-row>
           </div>
                      



            </div>

               <!-- <div class="content"  v-show="key1==1" style="padding:0;background:#f5f5f5">
             
                        <div style="padding:10px;background:#fff">
                                <div style="display:flex;    justify-content: space-between;align-items: center;
                                margin-bottom: 15px;">
                                <p style="font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #000000;
                line-height: 22px;">市场总监 · 业务经理 | 业务统计 </p>
                         

                         <el-button   size="mini" @click="exportOrderData3()"><i class="el-icon-download"></i>导出</el-button>  

                                <el-dialog title="创建管理员" :visible.sync="dialogFormVisible3">
                                <el-form :model="form1">
                                    <el-form-item label="姓名" :label-width="formLabelWidth">
                                    <el-input v-model="form1.name" autocomplete="off"></el-input>
                                    </el-form-item>
                                    <el-form-item label="电话" :label-width="formLabelWidth">
                                    <el-input v-model="form1.phone" autocomplete="off"></el-input>
                                    </el-form-item>
                                    <el-form-item label="管理员类型" :label-width="formLabelWidth">
                                    <el-select v-model="form1.region" placeholder="请选择活动区域">
                                        <el-option label="普通管理员" value="9"></el-option>
                                        <el-option label="管理员" value="10"></el-option>
                                    </el-select>
                                    </el-form-item>
                                </el-form>
                                <div slot="footer" class="dialog-footer">
                            
                                    <el-button type="primary" @click="tianjia" style="width:100%">添加</el-button>
                                </div>
                                </el-dialog>
                </div>


                    <el-table
      :border="true"
    :data="tb1"
   :header-cell-style="{background:'#FAFAFA'}"
    style="width: 100%"
 id="out-table3"
    >
   
   
     <el-table-column
     :show-overflow-tooltip="true" 
   
      prop="id"
      label="ID"
     >
    </el-table-column>
    <el-table-column
      label="姓名"
      prop="name" 
     >
    
    </el-table-column>

       <el-table-column
      label="手机号"
    prop="phone" 
      
    >
    
       </el-table-column>
       <el-table-column
      label="代理商数量"
    prop="dlssl" 
      
    >
    
       </el-table-column>
     
         <el-table-column
      label="琴行数量"
    prop="qhsl" 
      
    >
    
       </el-table-column>
                <el-table-column
      label="会员数量"
    prop="hysl" 
      
    >
    
       </el-table-column>
                <el-table-column
      label="订单数量"
    prop="ordersl" 
      
    >
    
       </el-table-column>
  </el-table>
                         
           </div>
                      



            </div> -->
             
          </el-tab-pane>

           <el-tab-pane label="技术总监" name="third">
              
   
      <div class="content"  style="padding:0;background:#f5f5f5">
             
                        <div style="padding:10px;background:#fff">
                                <div style="display:flex;    justify-content: space-between;align-items: center;
                                margin-bottom: 15px;">
                                <p style="font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #000000;
                line-height: 22px;">技术总监</p>


<div>
  <el-button   type="primary"  size="mini" @click="exportOrderData4()"><i class="el-icon-download"></i>导出</el-button>
                            <el-button
                        size="mini"
                           type="warning"
                        @click="dialogFormVisible33 = true">创建账号</el-button>
</div>
              
                                <el-dialog title="创建技术总监" :visible.sync="dialogFormVisible33" class="cj">
                                <el-form :model="form3">
                                    <el-form-item label="姓名" :label-width="formLabelWidth">
                                    <el-input v-model="form3.name" autocomplete="off"></el-input>
                                    </el-form-item>
                                    <el-form-item label="电话" :label-width="formLabelWidth">
                                    <el-input v-model="form3.phone" autocomplete="off"></el-input>
                                    </el-form-item>
                                      <el-form-item label="身份证号码" :label-width="formLabelWidth">
                    <el-input v-model="form3.id" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="平台证件编号" :label-width="formLabelWidth">
                    <el-input v-model="form3.key_2" autocomplete="off"></el-input>
                    </el-form-item>

                     <el-form-item label="入职时间" :label-width="formLabelWidth">
                                 
                                     <el-date-picker
                                            v-model="form3.worktime"
                                                             format="yyyy 年 MM 月"
                                                                  value-format="yyyy-MM"
                                            type="month"
                                            placeholder="选择月">
                                            </el-date-picker>
                                    </el-form-item>
   
   <div style="display:flex;justify-content: space-between;text-align:center;padding:0 10PX">
       <div style="    position: relative;">
           <p style="margin:20px 0">身份证正面</p>
          
          <img :src="producImg"   style=" margin:10px 0; 
    color: #8c939d;
    width: 178px;
    height: 178px;
      background-color: #fbfdff;
    
    border-radius: 6px;
    text-align: center;">
    <i class="el-icon-plus" style="position: absolute;top:148px;left:86px"></i>
          <input s type="file" name="avatar" style=" margin:10px 0;position: absolute;
width:178px;
height:178px;
right: 0;
top: 60px;
opacity: 0;
filter: alpha(opacity=0);
cursor: pointer " id="uppic" accept="image/gif,image/jpeg,image/jpg,image/png" @change="changeImage" ref="avatarInput"  class="uppic">
       </div>
       <div style="    position: relative;">
           <p style="margin:20px 0">身份证反面</p>
       
          <img :src="producImg1"  style=" margin:10px 0;   font-size: 1px;
    color: #8c939d;
    width: 178px;
    height: 178px;
     background-color: #fbfdff;
    
    border-radius: 6px;
    text-align: center;" >
      <i class="el-icon-plus" style="position: absolute;top:148px;left:86px"></i>
          <input type="file" name="avatar" style="margin:10px 0;position: absolute;
width:178px;
height:178px;
right: 0;
top: 60px;
opacity: 0;
filter: alpha(opacity=0);
cursor: pointer  " id="uppic" accept="image/gif,image/jpeg,image/jpg,image/png" @change="changeImage1" ref="avatarInput1"  class="uppic">
       </div>

                      
   </div>
                                    <el-form-item label="区域选择" :label-width="formLabelWidth">
                                    <el-cascader  :props="props" :options="options"   @change="changeProvinceCity">
                                        <template slot-scope="{ node, data }">
                                            <span>{{ data.label }}</span>
                                        
                                            <span v-if="!node.isLeaf"> ({{ data.children.length }}) </span>
                                        </template>
                                        </el-cascader>
                                    </el-form-item>
                                </el-form>
                                <div slot="footer" class="dialog-footer">
                            
                                    <el-button type="primary" @click="tianjia3" style="width:100%">添加</el-button>
                                </div>
                                </el-dialog>
                </div>

                    <div class="search" style="padding:20px 0px; display:flex;font-size:14px;justify-content: space-between;align-items: center;border-top:1px solid #ccc">
            <div style=" display:flex;">
              

               <div class="demo-input-suffix" style="margin-right:15px">
              <span style="margin-right:5px">姓名</span>  
                <el-input style="width:150px;height:30px"
                    placeholder="请输入"
                    size="small"
                    v-model="input22">
                </el-input>
            </div>

            <div class="demo-input-suffix" style="margin-right:15px">
              <span style="margin-right:5px">手机</span>  
                <el-input style="width:150px;height:30px"
                    placeholder="请输入"
                    size="small"
                    v-model="input33">
                </el-input>
            </div>

           <div class="demo-input-suffix" style="margin-right:15px">
              <span style="margin-right:5px">业务区域</span>  
                <el-cascader  :props="props" :options="options"   @change="changeProvinceCity"   size="small">
                    <template slot-scope="{ node, data }">
                        <span>{{ data.label }}</span>
                         <span v-if="!node.isLeaf"> ({{ data.children.length }}) </span>
                    </template>
                </el-cascader>
            </div>
            </div>
            <div style="display:flex" class="l">
                <p style="width: 80px;
height: 28px;line-height:28px;cursor: pointer; background:red;color:#fff;border-radius:3.5px;
border: 1px solid #DFE0E8;margin-right:20px;text-align:center" @click="ck3">重置</p> <p style="width: 80px;
height: 28px;line-height:28px;text-align:center;cursor: pointer;background:rgb(128, 181, 71);color:#fff;border-radius:3.5px;
border: 1px solid #DFE0E8;" @click="ck4">查询</p>
            </div>
        </div>
                                <el-table
                    :border="true"
                    :data="tableData"
                :header-cell-style="{background:'#FAFAFA'}"
                    style="width: 100%"
                 id="out-table4"
                 height='350'
                    >
                  <el-table-column
                    :show-overflow-tooltip="true" 
                
                    prop="key_2"
                    label="平台证件编号"
                    >
                    </el-table-column>
                
                
                    <el-table-column
                    :show-overflow-tooltip="true" 
                
                    prop="id"
                    label="ID"
                    >
                    </el-table-column>
                    <el-table-column
                    label="姓名"
                    prop="name" 
                    >
                    
                    </el-table-column>
 
                    <el-table-column
                    label="手机号"
                    prop="phone" 
                    
                    >
                    
                    </el-table-column>
                    <el-table-column
                        prop="createtime" 
                    label="最近登录时间" 
                        :show-overflow-tooltip="true" 
                    >
                    </el-table-column>
                    <el-table-column     
                    label="用户状态" 
                        :show-overflow-tooltip="true" 
                    >
                    <template slot-scope="scope" >
                    <p :class="{red:scope.row.isactive!==0}"> {{scope.row.isactive | capitalize}}</p>
                    </template>
                    </el-table-column>


                     <el-table-column
                    
                    label="查看服务区域"
                >
                    <template slot-scope="scope">

                        
                        <el-button
                        size="mini"
                            type="primary"
                          
                        @click="find1s(scope.$index, scope.row)">查看</el-button>
                            

                                            <el-dialog
                                        title="服务区域"
                                        :visible.sync="dialogVisiblefind2"
                                     
                                        class="he"
                                        style="text-align:left"
                                       >
                                        <h2 style="font-size:18px;font-weight:600;margin:10px 0">省份:</h2>
                                        <div class="a" style="  width: 100%;
     display: flex;
     flex-wrap: wrap;">
                                              <p class="" v-for="(item,index) in find1q" :key="index" style="width:25%;flex: 1;min-width: 25%; max-width: 25%">
                                           {{item}}
                                        </p>
                                        </div>
                                         <h2 style="font-size:18px;font-weight:600;margin:10px 0">城市:</h2>
                                        <div class="a" style="  width: 100%;
     display: flex;
     flex-wrap: wrap;">
                                              <p class="" v-for="(item,index) in find2q" :key="index" style="width:25%;flex: 1;min-width: 25%; max-width: 25%">
                                           {{item}}
                                        </p>
                                        </div>
                                           <h2 style="font-size:18px;font-weight:600;margin:10px 0">地区:</h2>
                                        <div class="a" style="  width: 100%;
     display: flex;
     flex-wrap: wrap;">
                                              <p class="" v-for="(item,index) in find3q" :key="index" style="width:25%;flex: 1;min-width: 25%; max-width: 25%">
                                           {{item}}
                                        </p>
                                        </div>
                                        <!-- <p>省份</p>
                                         <div class="" v-for="(item,index) in find2" :key="index">
                                            <p>{{item}}</p> 
                                        </div>
                                        <p>省份</p>
                                      <div class="" v-for="(item,index) in find3" :key="index">
                                            <p>{{item}}</p> 
                                        </div> -->

                                        </el-dialog>
                        
                    </template>
                    </el-table-column>
      <el-table-column
                    
                    label="操作"
                >
                    <template slot-scope="scope">

                        
                        <el-button
                        size="mini"
                            type="warning"
                            v-if='scope.row.isactive==0'
                        @click="addarea1(scope.$index, scope.row)">增加区域</el-button>
                        

                                       <el-dialog
                                        title="增加区域"
                                        :visible.sync="add12"

                                        style="text-align:center"
                                       >
                                  <div style=" display:block:margin:20px auto">
                                        <el-cascader  :props="props" :options="options"   @change="changeProvinceCity"   size="small">
                                                <template slot-scope="{ node, data }">
                                                    <span>{{ data.label }}</span>
                                                    <span v-if="!node.isLeaf"> ({{ data.children.length }}) </span>
                                                </template>
                                            </el-cascader>
                                  </div>
                                      
                                    <div style=" display:block:margin-top:20px"> <el-button
                                            size="mini"
                                                type="warning"
                                                style="margin:20px "
                                            @click="add1()">确定增加</el-button></div>
                                            
                                       </el-dialog>

                         
                    </template>
                    </el-table-column>
                <el-table-column
                    
                    label="操作"
                >
                    <template slot-scope="scope">

                        
                        <el-button
                        size="mini"
                            type="danger"
                            v-if='scope.row.isactive==0'
                        @click="handleEdit4(scope.$index, scope.row)">禁用账号</el-button>
                            <el-button
                                v-if='scope.row.isactive!==0'
                        size="mini"
                            type='info'
                        @click="handleEdit5(scope.$index, scope.row)">恢复账号</el-button>
                        
                    </template>
                    </el-table-column>
                      <el-table-column
                    
                    label="删除账号"
                >
                    <template slot-scope="scope">

                        
                        <el-button
                        size="mini"
                            type="danger"
                           
                        @click="sc2(scope.$index, scope.row)">删除账号</el-button>
                           
                        
                    </template>
                    </el-table-column>
                </el-table>

                   <el-row :gutter="24">
  <el-col :span="6" :offset="13">      <div class="block">
                    <el-pagination
      @size-change="handleSizeChange3"
      @current-change="handleCurrentChange3"
      :current-page="pagination4.page_index"
      :page-sizes="pagination4.page_sizes"
      :page-size="pagination4.page_size"
      :layout="pagination4.layout"
      :total='pagination4.total'
      style="margin-top:20px">
    </el-pagination>
                </div></el-col>

</el-row>
           </div>
                      



            </div>

        
             
          </el-tab-pane>

             <el-tab-pane label="技术专员" name="fourth">
                   
   
      <div class="content"  style="padding:0;background:#f5f5f5">
             
                        <div style="padding:10px;background:#fff">
                                <div style="display:flex;    justify-content: space-between;align-items: center;
                                margin-bottom: 15px;">
                                <p style="font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #000000;
                line-height: 22px;">技术专员</p>
                            <!-- <el-button
                        size="mini"
                        
                        @click="dialogFormVisible2 = true">创建账号</el-button> -->
 <el-button  type="primary"   size="mini" @click="exportOrderData6('技术专员','out-table6')"><i class="el-icon-download"></i>导出</el-button> 
                        

                                <el-dialog title="创建管理员" :visible.sync="dialogFormVisible2">
                                <el-form :model="form1">
                                    <el-form-item label="姓名" :label-width="formLabelWidth">
                                    <el-input v-model="form1.name" autocomplete="off"></el-input>
                                    </el-form-item>
                                    <el-form-item label="电话" :label-width="formLabelWidth">
                                    <el-input v-model="form1.phone" autocomplete="off"></el-input>
                                    </el-form-item>
                                    <el-form-item label="管理员类型" :label-width="formLabelWidth">
                                    <el-select v-model="form1.region" placeholder="请选择活动区域">
                                        <el-option label="普通管理员" value="9"></el-option>
                                        <el-option label="管理员" value="10"></el-option>
                                    </el-select>
                                    </el-form-item>
                                </el-form>
                                <div slot="footer" class="dialog-footer">
                            
                                    <el-button type="primary" @click="tianjia" style="width:100%">添加</el-button>
                                </div>
                                </el-dialog>
                </div>
           


          <div class="search" style="padding:20px 0px; display:flex;font-size:14px;justify-content: space-between;align-items: center;border-top:1px solid #ccc">
            <div style=" display:flex;">
              

               <div class="demo-input-suffix" style="margin-right:15px">
              <span style="margin-right:5px">姓名</span>  
                <el-input style="width:150px;height:30px"
                    placeholder="请输入"
                    size="small"
                    v-model="input31">
                </el-input>
            </div>

            <div class="demo-input-suffix" style="margin-right:15px">
              <span style="margin-right:5px">手机</span>  
                <el-input style="width:150px;height:30px"
                    placeholder="请输入"
                    size="small"
                    v-model="input32">
                </el-input>
            </div>

           <div class="demo-input-suffix" style="margin-right:15px">
              <span style="margin-right:5px">业务区域</span>  
                <el-cascader  :props="props" :options="options"   @change="changeProvinceCity"   size="small">
                    <template slot-scope="{ node, data }">
                        <span>{{ data.label }}</span>
                         <span v-if="!node.isLeaf"> ({{ data.children.length }}) </span>
                    </template>
                </el-cascader>
            </div>
            </div>
            <div style="display:flex" class="l">
                <p style="width: 80px;
height: 28px;line-height:28px;cursor: pointer;background:red;color:#fff;border-radius:3.5px;
border: 1px solid #DFE0E8;margin-right:20px;text-align:center" @click="ck11">重置</p> <p style="width: 80px;
height: 28px;line-height:28px;text-align:center;cursor: pointer;background:rgb(128, 181, 71);color:#fff;border-radius:3.5px;
border: 1px solid #DFE0E8;" @click="ck22">查询</p>
            </div>
        </div>
                                <el-table
                    :border="true"
                    :data="tableData"
                :header-cell-style="{background:'#FAFAFA'}"
                    style="width: 100%"
                  id="out-table6"
                  height='350'
                    >
                  <el-table-column
                    :show-overflow-tooltip="true" 
                
                    prop="key_2"
                    label="平台证件编号"
                    >
                    </el-table-column>
                
                
                    <el-table-column
                    :show-overflow-tooltip="true" 
                
                    prop="id"
                    label="ID"
                    >
                    </el-table-column>
                    <el-table-column
                    label="姓名"
                    prop="name" 
                    >
                    
                    </el-table-column>

                    <el-table-column
                    label="手机号"
                    prop="phone" 
                    
                    >
                    
                    </el-table-column>
                    <el-table-column
                        prop="createtime" 
                    label="最近登录时间" 
                        :show-overflow-tooltip="true" 
                    >
                    </el-table-column>
                    <el-table-column     
                    label="用户状态" 
                        :show-overflow-tooltip="true" 
                    >
                    <template slot-scope="scope" >
                    <p :class="{red:scope.row.isactive!==0}"> {{scope.row.isactive | capitalize}}</p>
                    </template>
                    </el-table-column>

   <el-table-column
                    
                    label="查看服务区域"
                >
                    <template slot-scope="scope">

                        
                        <el-button
                        size="mini"
                            type="primary"
                          
                        @click="find1a(scope.$index, scope.row)">查看</el-button>
                            
   <el-dialog
                                        title="服务区域"
                                        :visible.sync="dialogVisiblefind2a"
                                     
                                        class="he"
                                        style="text-align:left"
                                       >
                                        <h2 style="font-size:18px;font-weight:600;margin:10px 0">省份:</h2>
                                        <div class="a" style="  width: 100%;
     display: flex;
     flex-wrap: wrap;">
                                              <p class="" v-for="(item,index) in find11" :key="index" style="width:25%;flex: 1;min-width: 25%; max-width: 25%">
                                           {{item}}
                                        </p>
                                        </div>
                                         <h2 style="font-size:18px;font-weight:600;margin:10px 0">城市:</h2>
                                        <div class="a" style="  width: 100%;
     display: flex;
     flex-wrap: wrap;">
                                              <p class="" v-for="(item,index) in find22" :key="index" style="width:25%;flex: 1;min-width: 25%; max-width: 25%">
                                           {{item}}
                                        </p>
                                        </div>
                                           <h2 style="font-size:18px;font-weight:600;margin:10px 0">地区:</h2>
                                        <div class="a" style="  width: 100%;
     display: flex;
     flex-wrap: wrap;">
                                              <p class="" v-for="(item,index) in find33" :key="index" style="width:25%;flex: 1;min-width: 25%; max-width: 25%">
                                           {{item}}
                                        </p>
                                        </div>
                                        <!-- <p>省份</p>
                                         <div class="" v-for="(item,index) in find2" :key="index">
                                            <p>{{item}}</p> 
                                        </div>
                                        <p>省份</p>
                                      <div class="" v-for="(item,index) in find3" :key="index">
                                            <p>{{item}}</p> 
                                        </div> -->

                                        </el-dialog>
                        
                    </template>
                    </el-table-column>
                     
                  

                <el-table-column
                    
                    label="操作"
                >
                    <template slot-scope="scope">

                        
                        <el-button
                        size="mini"
                            type="danger"
                            v-if='scope.row.isactive==0'
                        @click="handleEdit6(scope.$index, scope.row)">禁用账号</el-button>
                            <el-button
                                v-if='scope.row.isactive!==0'
                        size="mini"
                            type='info'
                        @click="handleEdit7(scope.$index, scope.row)">恢复账号</el-button>
                        
                    </template>
                    </el-table-column>
                   <el-table-column
                    
                    label="删除账号"
                >
                      <template slot-scope="scope">

                        
                        <el-button
                        size="mini"
                            type="danger"
                           
                        @click="sc3(scope.$index, scope.row)">删除账号</el-button>
                           
                        
                    </template>
                    </el-table-column>
                </el-table>
                   <el-row :gutter="24">
  <el-col :span="6" :offset="13">      <div class="block">
                    <el-pagination
      @size-change="handleSizeChange4"
      @current-change="handleCurrentChange4"
      :current-page="pagination5.page_index"
      :page-sizes="pagination5.page_sizes"
      :page-size="pagination5.page_size"
      :layout="pagination5.layout"
      :total='pagination5.total'
      style="margin-top:20px">
    </el-pagination>
                </div></el-col>

</el-row>
           </div>
                      



            </div>

               <!-- <div class="content"  v-show="key1==1" style="padding:0;background:#f5f5f5">
             
                        <div style="padding:10px;background:#fff">
                                <div style="display:flex;    justify-content: space-between;align-items: center;
                                margin-bottom: 15px;">
                                <p style="font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #000000;
                line-height: 22px;">技术专员 | 业务统计 </p>
                         
 <el-button   size="mini" @click="exportOrderData6('技术专员 | 业务统计','out-table7')"><i class="el-icon-download"></i>导出</el-button> 
                        

                                <el-dialog title="创建管理员" :visible.sync="dialogFormVisible3">
                                <el-form :model="form1">
                                    <el-form-item label="姓名" :label-width="formLabelWidth">
                                    <el-input v-model="form1.name" autocomplete="off"></el-input>
                                    </el-form-item>
                                    <el-form-item label="电话" :label-width="formLabelWidth">
                                    <el-input v-model="form1.phone" autocomplete="off"></el-input>
                                    </el-form-item>
                                    <el-form-item label="管理员类型" :label-width="formLabelWidth">
                                    <el-select v-model="form1.region" placeholder="请选择活动区域">
                                        <el-option label="普通管理员" value="9"></el-option>
                                        <el-option label="管理员" value="10"></el-option>
                                    </el-select>
                                    </el-form-item>
                                </el-form>
                                <div slot="footer" class="dialog-footer">
                            
                                    <el-button type="primary" @click="tianjia" style="width:100%">添加</el-button>
                                </div>
                                </el-dialog>
                </div>


 <div class="search" style="padding:20px 0px; display:flex;font-size:14px;justify-content: space-between;align-items: center;border-top:1px solid #ccc">
            <div style=" display:flex;">
              

               <div class="demo-input-suffix" style="margin-right:15px">
              <span style="margin-right:5px">姓名</span>  
                <el-input style="width:150px;height:30px"
                    placeholder="请输入"
                    size="small"
                    v-model="yw31">
                </el-input>
            </div>

            <div class="demo-input-suffix" style="margin-right:15px">
              <span style="margin-right:5px">手机</span>  
                <el-input style="width:150px;height:30px"
                    placeholder="请输入"
                    size="small"
                    v-model="yw32">
                </el-input>
            </div>
            <div class="demo-input-suffix" style="margin-right:15px">
              <span style="margin-right:5px">平台证件编码</span>  
                <el-input style="width:150px;height:30px"
                    placeholder="请输入"
                    size="small"
                    v-model="yw33">
                </el-input>
            </div>

         
            </div>
            <div style="display:flex" class="l">
                <p style="width: 80px;
height: 28px;line-height:28px;cursor: pointer;
border: 1px solid #DFE0E8;margin-right:20px;text-align:center" @click="cz2">重置</p> <p style="width: 80px;
height: 28px;line-height:28px;text-align:center;cursor: pointer;
border: 1px solid #DFE0E8;" @click="cx2">查询</p>
            </div>
        </div>

                    <el-table
      :border="true"
    :data="tb1"
   :header-cell-style="{background:'#FAFAFA'}"
    style="width: 100%"
  id="out-table7"
    >
   
   
     <el-table-column
     :show-overflow-tooltip="true" 
   
      prop="jobnumber"
      label="平台证件编码"
     >
    </el-table-column>
    <el-table-column
      label="姓名"
      prop="name" 
     >
    
    </el-table-column>

       <el-table-column
      label="手机号"
    prop="phone" 
      
    >
    
       </el-table-column>
       <el-table-column
      label="代理商数量"
    prop="dlssl" 
      
    >
    
       </el-table-column>
         <el-table-column
      label="业务经理数"
    prop="ywjlsl" 
      
    >
    
       </el-table-column>
         <el-table-column
      label="琴行数量"
    prop="qhsl" 
      
    >
    
       </el-table-column>
                <el-table-column
      label="会员数量"
    prop="hysl" 
      
    >
    
       </el-table-column>
                <el-table-column
      label="订单数量"
    prop="ordersl" 
      
    >
    
       </el-table-column>
  </el-table>
                         
           </div>
                      



            </div> -->
             
          </el-tab-pane>
            
              <el-tab-pane label="代理商" name="five">
                    <div style="display:flex" class="list"> <p @click="show1" :class="{'actives':key1==0}">列表</p>
                    <p @click="show55" :class="{'actives':key1==1}">业务统计</p></div>   
   
      <div class="content" v-show="key1==0" style="padding:0;background:#f5f5f5">
             
                        <div style="padding:10px;background:#fff">
                                <div style="display:flex;    justify-content: space-between;align-items: center;
                                margin-bottom: 15px;">
                                <p style="font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #000000;
                line-height: 22px;">代理商</p>
                            <!-- <el-button
                        size="mini"
                        
                        @click="dialogFormVisible2 = true">创建账号</el-button> -->

                        <el-button size="mini"  type="primary" @click="exportOrderData6('代理商','out-table8')"><i class="el-icon-download"></i>导出</el-button>

                                <el-dialog title="创建管理员" :visible.sync="dialogFormVisible2">
                                <el-form :model="form1">
                                    <el-form-item label="姓名" :label-width="formLabelWidth">
                                    <el-input v-model="form1.name" autocomplete="off"></el-input>
                                    </el-form-item>
                                    <el-form-item label="电话" :label-width="formLabelWidth">
                                    <el-input v-model="form1.phone" autocomplete="off"></el-input>
                                    </el-form-item>
                                    <el-form-item label="管理员类型" :label-width="formLabelWidth">
                                    <el-select v-model="form1.region" placeholder="请选择活动区域">
                                        <el-option label="普通管理员" value="9"></el-option>
                                        <el-option label="管理员" value="10"></el-option>
                                    </el-select>
                                    </el-form-item>
                                </el-form>
                                <div slot="footer" class="dialog-footer">
                            
                                    <el-button type="primary" @click="tianjia" style="width:100%">添加</el-button>
                                </div>
                                </el-dialog>
                </div>


                   <div class="search" style="padding:20px 0px; display:flex;font-size:14px;justify-content: space-between;align-items: center;border-top:1px solid #ccc">
            <div style=" display:flex;">
              

               <div class="demo-input-suffix" style="margin-right:15px">
              <span style="margin-right:5px">姓名</span>  
                <el-input style="width:150px;height:30px"
                    placeholder="请输入"
                    size="small"
                    v-model="input41">
                </el-input>
            </div>

            <div class="demo-input-suffix" style="margin-right:15px">
              <span style="margin-right:5px">手机</span>  
                <el-input style="width:150px;height:30px"
                    placeholder="请输入"
                    size="small"
                    v-model="input42">
                </el-input>
            </div>

           <div class="demo-input-suffix" style="margin-right:15px">
              <span style="margin-right:5px">业务区域</span>  
                <el-cascader  :props="props" :options="options"   @change="changeProvinceCity"   size="small">
                    <template slot-scope="{ node, data }">
                        <span>{{ data.label }}</span>
                         <span v-if="!node.isLeaf"> ({{ data.children.length }}) </span>
                    </template>
                </el-cascader>
            </div>
            </div>
            <div style="display:flex" class="l">
                <p style="width: 80px;
height: 28px;line-height:28px;cursor: pointer;background:red;color:#fff;border-radius:3.5px;
border: 1px solid #DFE0E8;margin-right:20px;text-align:center" @click="ck31">重置</p> <p style="width: 80px;
height: 28px;line-height:28px;text-align:center;cursor: pointer;background:rgb(128, 181, 71);color:#fff;border-radius:3.5px;
border: 1px solid #DFE0E8;" @click="ck32">查询</p>
            </div>
        </div>
                                <el-table
                    :border="true"
                    :data="tableData"
                :header-cell-style="{background:'#FAFAFA'}"
                    style="width: 100%"
                id="out-table8"
                height='350'
                    >
                
                  <el-table-column
                    :show-overflow-tooltip="true" 
                
                    prop="key_2"
                    label="平台证件编号"
                    >
                    </el-table-column>
                
                    <el-table-column
                    :show-overflow-tooltip="true" 
                
                    prop="jobnumber"
                    label="ID"
                    >
                    </el-table-column>
                    <el-table-column
                    label="姓名"
                    prop="name" 
                    >
                    
                    </el-table-column>

                    <el-table-column
                    label="手机号"
                    prop="phone" 
                    
                    >
                    
                    </el-table-column>
                    <el-table-column
                        prop="createtime" 
                    label="最近登录时间" 
                        :show-overflow-tooltip="true" 
                    >
                    </el-table-column>
                    <el-table-column     
                    label="用户状态" 
                        :show-overflow-tooltip="true" 
                    >
                    <template slot-scope="scope" >
                    <p :class="{red:scope.row.isactive!==0}"> {{scope.row.isactive | capitalize}}</p>
                    </template>
                    </el-table-column>


                      <el-table-column
                    
                    label="查看服务区域"
                >
                    <template slot-scope="scope">

                        
                        <el-button
                        size="mini"
                            type="primary"
                          
                        @click="find33s(scope.$index, scope.row)">查看</el-button>
                            

                                         <el-dialog
                                        title="服务区域"
                                        :visible.sync="dialogVisiblefind3"
                                     
                                        class="he"
                                        style="text-align:left"
                                       >
                                        <h2 style="font-size:18px;font-weight:600;margin:10px 0">省份:</h2>
                                        <div class="a" style="  width: 100%;
     display: flex;
     flex-wrap: wrap;">
                                              <p class="" v-for="(item,index) in find1qq" :key="index" style="width:25%;flex: 1;min-width: 25%; max-width: 25%">
                                           {{item}}
                                        </p>
                                        </div>
                                         <h2 style="font-size:18px;font-weight:600;margin:10px 0">城市:</h2>
                                        <div class="a" style="  width: 100%;
     display: flex;
     flex-wrap: wrap;">
                                              <p class="" v-for="(item,index) in find2qq" :key="index" style="width:25%;flex: 1;min-width: 25%; max-width: 25%">
                                           {{item}}
                                        </p>
                                        </div>
                                           <h2 style="font-size:18px;font-weight:600;margin:10px 0">地区:</h2>
                                        <div class="a" style="  width: 100%;
     display: flex;
     flex-wrap: wrap;">
                                              <p class="" v-for="(item,index) in find3qq" :key="index" style="width:25%;flex: 1;min-width: 25%; max-width: 25%">
                                           {{item}}
                                        </p>
                                        </div>
                                        <!-- <p>省份</p>
                                         <div class="" v-for="(item,index) in find2" :key="index">
                                            <p>{{item}}</p> 
                                        </div>
                                        <p>省份</p>
                                      <div class="" v-for="(item,index) in find3" :key="index">
                                            <p>{{item}}</p> 
                                        </div> -->

                                        </el-dialog>
                        
                    </template>
                    </el-table-column>

                <el-table-column
                    
                    label="操作"
                >
                    <template slot-scope="scope">

                        
                        <el-button
                        size="mini"
                            type="danger"
                            v-if='scope.row.isactive==0'
                        @click="handleEdit8(scope.$index, scope.row)">禁用账号</el-button>
                            <el-button
                                v-if='scope.row.isactive!==0'
                        size="mini"
                            type='info'
                        @click="handleEdit9(scope.$index, scope.row)">恢复账号</el-button>
                        
                    </template>
                    </el-table-column>
                      <el-table-column
                    
                    label="删除账号"
                >
                      <template slot-scope="scope">

                        
                        <el-button
                        size="mini"
                            type="danger"
                           
                        @click="sc4(scope.$index, scope.row)">删除账号</el-button>
                           
                        
                    </template>
                    </el-table-column>
                </el-table>
                   <el-row :gutter="24">
  <el-col :span="6" :offset="13">      <div class="block">
                    <el-pagination
      @size-change="handleSizeChange5"
      @current-change="handleCurrentChange5"
      :current-page="pagination6.page_index"
      :page-sizes="pagination6.page_sizes"
      :page-size="pagination6.page_size"
      :layout="pagination6.layout"
      :total='pagination6.total'
      style="margin-top:20px">
    </el-pagination>
                </div></el-col>

</el-row>
           </div>
                      



            </div>

               <div class="content"  v-show="key1==1" style="padding:0;background:#f5f5f5">
             
                        <div style="padding:10px;background:#fff">
                                <div style="display:flex;    justify-content: space-between;align-items: center;
                                margin-bottom: 15px;">
                                <p style="font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #000000;
                line-height: 22px;">代理商 | 业务统计 </p>
                         
<el-button size="mini"  type="primary" @click="exportOrderData6('代理商 | 业务统计','out-table9')"><i class="el-icon-download"></i>导出</el-button>
                        

                               
                </div>

    <div class="search" style="padding:20px 0px; display:flex;font-size:14px;justify-content: space-between;align-items: center;border-top:1px solid #ccc">
            <div style=" display:flex;">
              

           

            <div class="demo-input-suffix" style="margin-right:15px">
              <span style="margin-right:5px">手机</span>  
                <el-input style="width:150px;height:30px"
                    placeholder="请输入"
                    size="small"
                    v-model="dls1">
                </el-input>
            </div>
          
         
            </div>
            <div style="display:flex" class="l">
                <p style="width: 80px;
height: 28px;line-height:28px;cursor: pointer;background:red;color:#fff;border-radius:3.5px;
border: 1px solid #DFE0E8;margin-right:20px;text-align:center" @click="czd">重置</p> <p style="width: 80px;
height: 28px;line-height:28px;text-align:center;cursor: pointer;background:rgb(128, 181, 71);color:#fff;border-radius:3.5px;
border: 1px solid #DFE0E8;" @click="cxd">查询</p>
            </div>
        </div>
                    <el-table
      :border="true"
    :data="tb1"
   :header-cell-style="{background:'#FAFAFA'}"
    style="width: 100%"
 id="out-table9"
    >
           
   
     <el-table-column
     :show-overflow-tooltip="true" 
   
      prop="jobnumber"
      label="ID"
     >
    </el-table-column>
    <el-table-column
      label="姓名"
      prop="name" 
     >
    
    </el-table-column>

       <el-table-column
      label="手机号"
    prop="phone" 
      
    >
    
       </el-table-column>
    
         <el-table-column
      label="业务经理数"
    prop="ywjlsl" 
      
    >
    
       </el-table-column>
         <el-table-column
      label="琴行数量"
    prop="qhsl" 
      
    >
    
       </el-table-column>
                <el-table-column
      label="会员数量"
    prop="hysl" 
      
    >
    
       </el-table-column>
                <el-table-column
      label="订单数量"
    prop="ordersl" 
      
    >
    
       </el-table-column>
  </el-table>
                      <el-row :gutter="24">
  <el-col :span="6" :offset="13">      <div class="block">
                    <el-pagination
      @size-change="handleSizeChange33"
      @current-change="handleCurrentChange33"
      :current-page="pagination44.page_index"
      :page-sizes="pagination44.page_sizes"
      :page-size="pagination44.page_size"
      :layout="pagination44.layout"
      :total='pagination44.total'
      style="margin-top:20px">
    </el-pagination>
                </div></el-col>

</el-row>         
           </div>
                      



            </div>
             
          </el-tab-pane>

   <el-tab-pane label="代理商·业务经理" name="six">
                    <div style="display:flex" class="list"> <p @click="show1" :class="{'actives':key1==0}">列表</p>
                    <p @click="show22" :class="{'actives':key1==1}">业务统计</p></div>   
   
      <div class="content" v-show="key1==0" style="padding:0;background:#f5f5f5">
             
                        <div style="padding:10px;background:#fff">
                                <div style="display:flex;    justify-content: space-between;align-items: center;
                                margin-bottom: 15px;">
                                <p style="font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #000000;
                line-height: 22px;">代理商·业务经理</p>
                            <!-- <el-button
                        size="mini"
                        
                        @click="dialogFormVisible2 = true">创建账号</el-button> -->
        <el-button size="mini"  type="primary" @click="exportOrderData6('代理商·业务经理','out-table10')"><i class="el-icon-download"></i>导出</el-button>
                        

                              
                </div>

                      <div class="search" style="padding:20px 0px; display:flex;font-size:14px;justify-content: space-between;align-items: center;border-top:1px solid #ccc">
            <div style=" display:flex;">
              

               <div class="demo-input-suffix" style="margin-right:15px">
              <span style="margin-right:5px">姓名</span>  
                <el-input style="width:150px;height:30px"
                    placeholder="请输入"
                    size="small"
                    v-model="input51">
                </el-input>
            </div>

            <div class="demo-input-suffix" style="margin-right:15px">
              <span style="margin-right:5px">手机</span>  
                <el-input style="width:150px;height:30px"
                    placeholder="请输入"
                    size="small"
                    v-model="input52">
                </el-input>
            </div>

           <div class="demo-input-suffix" style="margin-right:15px">
              <span style="margin-right:5px">业务区域</span>  
                <el-cascader  :props="props" :options="options"   @change="changeProvinceCity"   size="small">
                    <template slot-scope="{ node, data }">
                        <span>{{ data.label }}</span>
                         <span v-if="!node.isLeaf"> ({{ data.children.length }}) </span>
                    </template>
                </el-cascader>
            </div>
            </div>
            <div style="display:flex" class="l">
                <p style="width: 80px;
height: 28px;line-height:28px;cursor: pointer;background:red;color:#fff;border-radius:3.5px;
border: 1px solid #DFE0E8;margin-right:20px;text-align:center" @click="ck51">重置</p> <p style="width: 80px;
height: 28px;line-height:28px;text-align:center;cursor: pointer;background:rgb(128, 181, 71);color:#fff;border-radius:3.5px;
border: 1px solid #DFE0E8;" @click="ck52">查询</p>
            </div>
        </div>
                                <el-table
                    :border="true"
                    :data="tableData"
                :header-cell-style="{background:'#FAFAFA'}"
                    style="width: 100%"
                 id="out-table10"
                 height='350'
                    >
                
                  <el-table-column
                    :show-overflow-tooltip="true" 
                
                    prop="key_2"
                    label="平台证件编号"
                    >
                    </el-table-column>
                
                    <el-table-column
                    :show-overflow-tooltip="true" 
                
                    prop="jobnumber"
                    label="ID"
                    >
                    </el-table-column>
                    <el-table-column
                    label="姓名"
                    prop="name" 
                    >
                    
                    </el-table-column>

                    <el-table-column
                    label="手机号"
                    prop="phone" 
                    
                    >
                    
                    </el-table-column>


                    <el-table-column
                        prop="createtime" 
                    label="最近登录时间" 
                        :show-overflow-tooltip="true" 
                    >
                    </el-table-column>
                    <el-table-column     
                    label="用户状态" 
                        :show-overflow-tooltip="true" 
                    >
                    <template slot-scope="scope" >
                    <p :class="{red:scope.row.isactive!==0}"> {{scope.row.isactive | capitalize}}</p>
                    </template>
                    </el-table-column>


                    
                     <el-table-column
                    
                    label="查看服务区域"
                >
                    <template slot-scope="scope">

                        
                        <el-button
                        size="mini"
                            type="primary"
                          
                        @click="find3a(scope.$index, scope.row)">查看</el-button>
                            

                                        <el-dialog
                                        title="服务区域"
                                        :visible.sync="dialogVisiblefind3a"
                                        width="30%"
                                       >
                                        <p>{{options1}}</p>
                                       
                                        </el-dialog>
                        
                    </template>
                    </el-table-column>

                <el-table-column
                    
                    label="操作"
                >
                    <template slot-scope="scope">

                        
                        <el-button
                        size="mini"
                            type="danger"
                            v-if='scope.row.isactive==0'
                        @click="handleEdit10(scope.$index, scope.row)">禁用账号</el-button>
                            <el-button
                                v-if='scope.row.isactive!==0'
                        size="mini"
                            type='info'
                        @click="handleEdit11(scope.$index, scope.row)">恢复账号</el-button>
                        
                    </template>
                    </el-table-column>
                         <el-table-column
                    
                    label="删除账号"
                >
                      <template slot-scope="scope">

                        
                        <el-button
                        size="mini"
                            type="danger"
                           
                        @click="sc5(scope.$index, scope.row)">删除账号</el-button>
                           
                        
                    </template>
                    </el-table-column>
                </el-table>
                   <el-row :gutter="24">
  <el-col :span="6" :offset="13">      <div class="block">
                    <el-pagination
      @size-change="handleSizeChange6"
      @current-change="handleCurrentChange6"
      :current-page="pagination7.page_index"
      :page-sizes="pagination7.page_sizes"
      :page-size="pagination7.page_size"
      :layout="pagination7.layout"
      :total='pagination7.total'
      style="margin-top:20px">
    </el-pagination>
                </div></el-col>

</el-row>
           </div>
                      



            </div>

               <div class="content"  v-show="key1==1" style="padding:0;background:#f5f5f5">
             
                        <div style="padding:10px;background:#fff">
                                <div style="display:flex;    justify-content: space-between;align-items: center;
                                margin-bottom: 15px;">
                                <p style="font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #000000;
                line-height: 22px;">代理商·业务经理 | 业务统计 </p>
                         
<el-button size="mini"  type="primary" @click="exportOrderData6('代理商·业务经理 | 业务统计','out-table11')"><i class="el-icon-download"></i>导出</el-button> 
                        

                </div>
 
           <div class="search" style="padding:20px 0px; display:flex;font-size:14px;justify-content: space-between;align-items: center;border-top:1px solid #ccc">
            <div style=" display:flex;">
              

         
            <div class="demo-input-suffix" style="margin-right:15px">
              <span style="margin-right:5px">手机</span>  
                <el-input style="width:150px;height:30px"
                    placeholder="请输入"
                    size="small"
                    v-model="dls2">
                </el-input>
            </div>
         

         
            </div>
            <div style="display:flex" class="l">
                <p style="width: 80px;
height: 28px;line-height:28px;cursor: pointer;background:red;color:#fff;border-radius:3.5px;
border: 1px solid #DFE0E8;margin-right:20px;text-align:center" @click="czd1">重置</p> <p style="width: 80px;
height: 28px;line-height:28px;text-align:center;cursor: pointer;background:rgb(128, 181, 71);color:#fff;border-radius:3.5px;
border: 1px solid #DFE0E8;" @click="cxd1">查询</p>
            </div>
        </div>

                    <el-table
      :border="true"
    :data="tb2"
   :header-cell-style="{background:'#FAFAFA'}"
    style="width: 100%"
 id="out-table11"
    >
   
   
     <el-table-column
     :show-overflow-tooltip="true" 
   
      prop="jobnumber"
      label="ID"
     >
    </el-table-column>
    <el-table-column
      label="姓名"
      prop="name" 
     >
    
    </el-table-column>

       <el-table-column
      label="手机号"
    prop="phone" 
      
    >
    
       </el-table-column>
      
     
         <el-table-column
      label="琴行数量"
    prop="qhsl" 
      
    >
    
       </el-table-column>
                <el-table-column
      label="会员数量"
    prop="customersl" 
      
    >
    
       </el-table-column>
                <el-table-column
      label="订单数量"
    prop="ordercount" 
      
    >
    
       </el-table-column>
  </el-table>

     <el-row :gutter="24">
  <el-col :span="6" :offset="13">      <div class="block">
                    <el-pagination
      @size-change="handleSizeChange55"
      @current-change="handleCurrentChange55"
      :current-page="pagination66.page_index"
      :page-sizes="pagination66.page_sizes"
      :page-size="pagination66.page_size"
      :layout="pagination66.layout"
      :total='pagination66.total'
      style="margin-top:20px">
    </el-pagination>
                </div></el-col>

</el-row>
                         
           </div>
                      



            </div>
             
          </el-tab-pane>
            
          
            <el-tab-pane label="调律师" name="seven"><div class="content" style="padding:20px 0;background:#f5f5f5">
             
           <div style="padding:10px;background:#fff">
                 <div style="display:flex;    justify-content: space-between;align-items: center;
                margin-bottom: 15px;">
                    <p style="font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #000000;
            line-height: 22px;">调律师列表</p>
            <!-- <el-button
          size="mini"
           
           @click="dialogFormVisible3 = true">创建账号</el-button> -->

         <el-button size="mini"  type="primary" @click="exportOrderData6('调律师','out-table12')"><i class="el-icon-download"></i>导出</el-button>

                <el-dialog title="创建管理员" :visible.sync="dialogFormVisible3">
                <el-form :model="form1">
                    <el-form-item label="姓名" :label-width="formLabelWidth">
                    <el-input v-model="form1.name" autocomplete="off"></el-input>
                    </el-form-item>
                     <el-form-item label="电话" :label-width="formLabelWidth">
                    <el-input v-model="form1.phone" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="管理员类型" :label-width="formLabelWidth">
                    <el-select v-model="form1.region" placeholder="请选择活动区域">
                        <el-option label="普通管理员" value="9"></el-option>
                        <el-option label="管理员" value="10"></el-option>
                    </el-select>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
            
                    <el-button type="primary" @click="tianjia" style="width:100%">添加</el-button>
                </div>
                </el-dialog>
   </div>

       <div class="search" style="padding:20px 0px; display:flex;font-size:14px;justify-content: space-between;align-items: center;border-top:1px solid #ccc">
            <div style=" display:flex;">
              

               <div class="demo-input-suffix" style="margin-right:15px">
              <span style="margin-right:5px">姓名</span>  
                <el-input style="width:150px;height:30px"
                    placeholder="请输入"
                    size="small"
                    v-model="input61">
                </el-input>
            </div>

            <div class="demo-input-suffix" style="margin-right:15px">
              <span style="margin-right:5px">手机</span>  
                <el-input style="width:150px;height:30px"
                    placeholder="请输入"
                    size="small"
                    v-model="input62">
                </el-input>
            </div>

           <div class="demo-input-suffix" style="margin-right:15px">
              <span style="margin-right:5px">业务区域</span>  
                <el-cascader  :props="props" :options="options"   @change="changeProvinceCity"   size="small">
                    <template slot-scope="{ node, data }">
                        <span>{{ data.label }}</span>
                         <span v-if="!node.isLeaf"> ({{ data.children.length }}) </span>
                    </template>
                </el-cascader>
            </div>
            </div>
            <div style="display:flex" class="l">
                <p style="width: 80px;
height: 28px;line-height:28px;cursor: pointer;background:red;color:#fff;border-radius:3.5px;
border: 1px solid #DFE0E8;margin-right:20px;text-align:center" @click="ck61">重置</p> <p style="width: 80px;
height: 28px;line-height:28px;text-align:center;cursor: pointer;background:rgb(128, 181, 71);color:#fff;border-radius:3.5px;
border: 1px solid #DFE0E8;" @click="ck62">查询</p>
            </div>
        </div>
                  <el-table
      :border="true"
    :data="tableData"
   :header-cell-style="{background:'#FAFAFA'}"
   height='350'
    style="width: 100%"
id="out-table12"
    >
     <el-table-column
                    :show-overflow-tooltip="true" 
                
                    prop="key_2"
                    label="平台证件编号"
                    >
                    </el-table-column>
                
   
     <el-table-column
     :show-overflow-tooltip="true" 
   
      prop="jobnumber"
      label="ID"
     >
    </el-table-column>
    <el-table-column
      label="姓名"
      prop="name" 
     >
    
    </el-table-column>

       <el-table-column
      label="手机号"
    prop="phone" 
      
    >
    
    </el-table-column>
      <el-table-column
        prop="createtime" 
      label="最近登录时间" 
           :show-overflow-tooltip="true" 
      >
    </el-table-column>
      <el-table-column     
      label="用户状态" 
           :show-overflow-tooltip="true" 
     >
      <template slot-scope="scope" >
       <p :class="{red:scope.row.isactive!==0}"> {{scope.row.isactive | capitalize}}</p>
      </template>
    </el-table-column>

  <el-table-column
                    
                    label="查看服务区域"
                >
                    <template slot-scope="scope">

                        
                        <el-button
                        size="mini"
                            type="primary"
                          
                        @click="find3b(scope.$index, scope.row)">查看</el-button>
                            

                                        <el-dialog
                                        title="服务区域"
                                        :visible.sync="dialogVisiblefind3b"
                                        width="30%"
                                       >
                                        <p>{{options1}}</p>
                                       
                                        </el-dialog>
                        
                    </template>
                    </el-table-column>
    

   <el-table-column
     
      label="操作"
   >
     <template slot-scope="scope">

         
        <el-button
          size="mini"
            type="danger"
            v-if='scope.row.isactive==0'
           @click="handleEdit12(scope.$index, scope.row)">禁用账号</el-button>
             <el-button
                 v-if='scope.row.isactive!==0'
          size="mini"
            type='info'
           @click="handleEdit13(scope.$index, scope.row)">恢复账号</el-button>
        
     </template>
    </el-table-column>
      <el-table-column
                    
                    label="删除账号"
                >
                      <template slot-scope="scope">

                        
                        <el-button
                        size="mini"
                            type="danger"
                           
                        @click="sc6(scope.$index, scope.row)">删除账号</el-button>
                           
                        
                    </template>
                    </el-table-column>
  </el-table>
     <el-row :gutter="24">
  <el-col :span="6" :offset="13">      <div class="block">
                    <el-pagination
      @size-change="handleSizeChange7"
      @current-change="handleCurrentChange7"
      :current-page="pagination8.page_index"
      :page-sizes="pagination8.page_sizes"
      :page-size="pagination8.page_size"
      :layout="pagination8.layout"
      :total='pagination8.total'
      style="margin-top:20px">
    </el-pagination>
                </div></el-col>

</el-row>
           </div>
                      



            </div></el-tab-pane>
            <el-tab-pane label="琴行" name="eight"><div class="content" style="padding:20px 0;background:#f5f5f5">
              <div style="display:flex" class="list"> <p @click="shows" :class="{'actives':key1==0}">列表</p>
                    <p @click="shows1" :class="{'actives':key1==1}">业务统计</p></div>   
   
      <div class="content" v-show="key1==0" style="padding:0;background:#f5f5f5">
           <div style="padding:10px;background:#fff">
                 <div style="display:flex;    justify-content: space-between;align-items: center;
                margin-bottom: 15px;">
                    <p style="font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #000000;
            line-height: 22px;">琴行列表</p>
       
<el-button size="mini"  type="primary" @click="exportOrderData6('琴行列表','out-table13')"><i class="el-icon-download"></i>导出</el-button>
         

   </div>

          <div class="search" style="padding:20px 0px; display:flex;font-size:14px;justify-content: space-between;align-items: center;border-top:1px solid #ccc">
            <div style=" display:flex;">
              

               <div class="demo-input-suffix" style="margin-right:15px">
              <span style="margin-right:5px">姓名</span>  
                <el-input style="width:150px;height:30px"
                    placeholder="请输入"
                    size="small"
                    v-model="input71">
                </el-input>
            </div>

            <div class="demo-input-suffix" style="margin-right:15px">
              <span style="margin-right:5px">手机</span>  
                <el-input style="width:150px;height:30px"
                    placeholder="请输入"
                    size="small"
                    v-model="input72">
                </el-input>
            </div>

           <div class="demo-input-suffix" style="margin-right:15px">
              <span style="margin-right:5px">业务区域</span>  
                <el-cascader  :props="props" :options="options"   @change="changeProvinceCity"   size="small">
                    <template slot-scope="{ node, data }">
                        <span>{{ data.label }}</span>
                         <span v-if="!node.isLeaf"> ({{ data.children.length }}) </span>
                    </template>
                </el-cascader>
            </div>
            </div>
            <div style="display:flex" class="l">
                <p style="width: 80px;
height: 28px;line-height:28px;cursor: pointer;background:red;color:#fff;border-radius:3.5px;
border: 1px solid #DFE0E8;margin-right:20px;text-align:center" @click="ck71">重置</p> <p style="width: 80px;
height: 28px;line-height:28px;text-align:center;cursor: pointer;background:rgb(128, 181, 71);color:#fff;border-radius:3.5px;
border: 1px solid #DFE0E8;" @click="ck72">查询</p>
            </div>
        </div>
                  <el-table
      :border="true"
    :data="tableData"
   :header-cell-style="{background:'#FAFAFA'}"
    style="width: 100%"
 id="out-table13"
 height='350'
    >
     <el-table-column
                    :show-overflow-tooltip="true" 
                
                    prop="key_2"
                    label="平台证件编号"
                    >
                    </el-table-column>
     <el-table-column
     :show-overflow-tooltip="true" 
   
      prop="jobnumber"
      label="ID"
     >
    </el-table-column>
    <el-table-column
      label="姓名"
      prop="name" 
     >
    
    </el-table-column>

       <el-table-column
      label="手机号"
    prop="phone" 
      
    >
    
    </el-table-column>
      <el-table-column
        prop="createtime" 
      label="最近登录时间" 
           :show-overflow-tooltip="true" 
      >
    </el-table-column>
      <el-table-column     
      label="用户状态" 
           :show-overflow-tooltip="true" 
     >
      <template slot-scope="scope" >
       <p :class="{red:scope.row.isactive!==0}"> {{scope.row.isactive | capitalize}}</p>
      </template>
    </el-table-column>

  <el-table-column
                    
                    label="查看服务区域"
                >
                    <template slot-scope="scope">

                        
                        <el-button
                        size="mini"
                            type="primary"
                          
                        @click="find3c(scope.$index, scope.row)">查看</el-button>
                            

                                        <el-dialog
                                        title="服务区域"
                                        :visible.sync="dialogVisiblefind3c"
                                        width="30%"
                                       >
                                        <p>{{options1}}</p>
                                       
                                        </el-dialog>
                        
                    </template>
                    </el-table-column>
    

   <el-table-column
     
      label="操作"
   >
     <template slot-scope="scope">

         
        <el-button
          size="mini"
            type="danger"
            v-if='scope.row.isactive==0'
           @click="handleEdit14(scope.$index, scope.row)">禁用账号</el-button>
             <el-button
                 v-if='scope.row.isactive!==0'
          size="mini"
            type='info'
           @click="handleEdit15(scope.$index, scope.row)">恢复账号</el-button>
        
     </template>
    </el-table-column>
      <el-table-column
                    
                    label="删除账号"
                >
                      <template slot-scope="scope">

                        
                        <el-button
                        size="mini"
                            type="danger"
                           
                        @click="sc7(scope.$index, scope.row)">删除账号</el-button>
                           
                        
                    </template>
                    </el-table-column>
  </el-table>
     <el-row :gutter="24">
  <el-col :span="6" :offset="13">      <div class="block">
                    <el-pagination
      @size-change="handleSizeChange8"
      @current-change="handleCurrentChange8"
      :current-page="pagination9.page_index"
      :page-sizes="pagination9.page_sizes"
      :page-size="pagination9.page_size"
      :layout="pagination9.layout"
      :total='pagination9.total'
      style="margin-top:20px">
    </el-pagination>
                </div></el-col>

</el-row>
           </div>

     

      </div>

    <div class="content" v-show="key1==1" style="padding:0;background:#f5f5f5">
           <div style="display:flex;    justify-content: space-between;align-items: center;
                padding-bottom: 15px;background:#fff">
                    <p style="font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            padding-top:20px;
            padding-left:15px;
            font-weight: 500;
            color: #000000;
            line-height: 22px;">琴行业务统计</p>
       

           </div>
              <div class="search" style="padding:20px 0px; display:flex;font-size:14px;justify-content: space-between;align-items: center;border-top:1px solid #ccc">
            <div style=" display:flex;">
              

         
            <div class="demo-input-suffix" style="margin-right:15px">
              <span style="margin-right:5px">手机</span>  
                <el-input style="width:150px;height:30px"
                    placeholder="请输入"
                    size="small"
                    v-model="dls3">
                </el-input>
            </div>
         

         
            </div>
            <div style="display:flex" class="l">
                <p style="width: 80px;
height: 28px;line-height:28px;cursor: pointer;background:red;color:#fff;border-radius:3.5px;
border: 1px solid #DFE0E8;margin-right:20px;text-align:center" @click="czq">重置</p> <p style="width: 80px;
height: 28px;line-height:28px;text-align:center;cursor: pointer;background:rgb(128, 181, 71);color:#fff;border-radius:3.5px;
border: 1px solid #DFE0E8;" @click="cxq">查询</p>
            </div>
        </div>
              <el-table
      :border="true"
    :data="tb1"
   :header-cell-style="{background:'#FAFAFA'}"
    style="width: 100%"
  id="out-table7"
    >
   
   
     <el-table-column
     :show-overflow-tooltip="true" 
   
      prop="jobnumber"
      label="平台证件编码"
     >
    </el-table-column>
    <el-table-column
      label="姓名"
      prop="name" 
     >
    
    </el-table-column>

       <el-table-column
      label="手机号"
    prop="phone" 
      
    >
    
       </el-table-column>
     
                <el-table-column
      label="会员数量"
    prop="hycount" 
      
    >
    
       </el-table-column>
                <el-table-column
      label="订单数量"
    prop="ordercount" 
      
    >
    
       </el-table-column>
  </el-table>
    <el-row :gutter="24">
  <el-col :span="6" :offset="13">      <div class="block">
                    <el-pagination
      @size-change="handleSizeChange77"
      @current-change="handleCurrentChange77"
      :current-page="pagination88.page_index"
      :page-sizes="pagination88.page_sizes"
      :page-size="pagination88.page_size"
      :layout="pagination88.layout"
      :total='pagination88.total'
      style="margin-top:20px">
    </el-pagination>
                </div></el-col>

</el-row>
         </div>

            </div></el-tab-pane>
        </el-tabs>
    
       </div> 

            
  </div>
</template>

<script>

import FileSaver from 'file-saver'
import XLSX from 'xlsx'

export default {
     
  
    filters: {
  capitalize: function (value) {
  if(value==0){
      return '正常'
    
  }else {
      return '禁用'
  }
  }
},
 data(){
        return{
            dls1:'',
        dls2:'',
          dls3:'',
            add:false,
            find1:[],
            find2:[],
            find3:[],
              find1q:[],
            find2q:[],
            find3q:[],
               find1qq:[],
            find2qq:[],
            find3qq:[],
              find11:[],
            find22:[],
            find33:[],
              find111:[],
            find222:[],
            find333:[],
            tb2:[],
            yw11:'',
            yw12:'',
            yw13:" ",
              yw21:' ',
            yw22:' ',
            yw23:" ",
             yw31:'',
            yw32:'',
            yw33:" ",
            pagination:{
                page_index:1,
                total:0,
            page_size:10,
            page_sizes:[10,15,20],
            layout:"total,sizes,prev,pager,next,jumper"
            },
               pagination88:{
                page_index:1,
                total:0,
            page_size:10,
            page_sizes:[10,15,20],
            layout:"total,sizes,prev,pager,next,jumper"
            },
               pagination1:{
                page_index:1,
                total:0,
            page_size:10,
            page_sizes:[10,15,20],
            layout:"total,sizes,prev,pager,next,jumper"
            },

               pagination22:{
                page_index:1,
                total:0,
            page_size:10,
            page_sizes:[10,15,20],
            layout:"total,sizes,prev,pager,next,jumper"
            },
                pagination44:{
                page_index:1,
                total:0,
            page_size:10,
            page_sizes:[10,15,20],
            layout:"total,sizes,prev,pager,next,jumper"
            },
             pagination66:{
                page_index:1,
                total:0,
            page_size:10,
            page_sizes:[10,15,20],
            layout:"total,sizes,prev,pager,next,jumper"
            },
              pagination3:{
                page_index:1,
                total:0,
            page_size:10,
            page_sizes:[10,15,20],
            layout:"total,sizes,prev,pager,next,jumper"
            },
             pagination4:{
                page_index:1,
                total:0,
            page_size:10,
            page_sizes:[10,15,20],
            layout:"total,sizes,prev,pager,next,jumper"
            },
             pagination5:{
                page_index:1,
                total:0,
            page_size:10,
            page_sizes:[10,15,20],
            layout:"total,sizes,prev,pager,next,jumper"
            },
             pagination6:{
                page_index:1,
                total:0,
            page_size:10,
            page_sizes:[10,15,20],
            layout:"total,sizes,prev,pager,next,jumper"
            },
              pagination7:{
                page_index:1,
                total:0,
            page_size:10,
            page_sizes:[10,15,20],
            layout:"total,sizes,prev,pager,next,jumper"
            },
             pagination8:{
                page_index:1,
                total:0,
            page_size:10,
            page_sizes:[10,15,20],
            layout:"total,sizes,prev,pager,next,jumper"
            },
             pagination9:{
                page_index:1,
                total:0,
            page_size:10,
            page_sizes:[10,15,20],
            layout:"total,sizes,prev,pager,next,jumper"
            },
             currentPage4: 1,
              dialogVisiblefind3c:false,
            dialogVisiblefind3b:false,
            dialogVisiblefind3a:false,
            dialogVisiblefind2a:false,
            dialogVisiblefind1a:false,
            result:'',
            input61:'',
            input62:'',
             input71:'',
            input72:'',
            input51:'',
            input52:'',
            input41:'',
            input42:'',
             input31:'',
             input32:'',
             input2:'',
              input3:'',
              input4:'',
              input5:'',
                 input22:'',
              input33:'',
            value1:"",
            select:'',
               producImg: '',
          
               producImg1: '',
               props: { multiple: true },
            key1:0,
              tabPosition: '列表',
             form1: {
          name: '',
          phone:'',
          region: '',
          key_2:'',
          id:'',
          worktime:'',
          
       
        },
            form2: {
          name: '',
          phone:'',
          region: '',
       
        },
         form3: {
           name: '',
          phone:'',
          region: '',
          key_2:'',
          id:'',
          worktime:'',
       
        },
dialogFormVisible3:false,
options:[],
options1:[],
          add12:false,
            isactive:0,
            arr:'',
           dialogFormVisible2:false,
            dialogFormVisible1:false,
            dialogFormVisible33:false,
            dialogFormVisible11:false,
                      dialogVisiblefind3:false,
                dialogFormVisible:false, form: {
          name: '',
          region: '',

          date1: '',
          date2: '',
          date3:[],
          delivery: false,
          type: [],
          resource: '',
          desc: ''
        },
        formLabelWidth: '120px',
        info:[],
        activeName: 'first',
        tableData: [],
        tb1:[],
        data1:[],
        data2:[],
        password:'',
        password2:false,
        password3:'',
        dialogVisiblefind1: false,       dialogVisiblefind2:false
        }
        },
       methods:{
          addarea(index,row){
             
              this.add=true
              this.jobnumber = row.jobnumber
          },
            addarea1(index,row){
  
              this.add12=true
              this.jobnumber = row.jobnumber
          },
          add1(){
                this.$axios({
                                        method: "post",
                                        url: "/s/backend/api/user_service_area_add",
                                        params: {
                                        accessToken : localStorage.getItem('token')
                                        },
                                        data:{
                                              "area": this.arr,
                                             "jobnumber": this.jobnumber
                                        },
                                        }) .then(res=>{
                                            if(res.data.code ==0){
                                                this.add12 = false
                                                  this.$message({
                                            message:'添加成功',
                                            type: 'success'
                                                });
                                            }
                                          
                        })
        .catch(function(){
           
        })  
          },
               adds(){
                this.$axios({
                                        method: "post",
                                        url: "/s/backend/api/user_service_area_add",
                                        params: {
                                        accessToken : localStorage.getItem('token')
                                        },
                                        data:{
                                              "area": this.arr,
                                             "jobnumber": this.jobnumber
                                        },
                                        }) .then(res=>{
                                            if(res.data.code ==0){
                                                this.add = false
                                                  this.$message({
                                            message:'添加成功',
                                            type: 'success'
                                                });
                                            }
                                          
                        })
        .catch(function(){
           
        })  
          },
          cx(){
              this.$axios.get("/s/backend/api/sczj_bussiness_statistic", {
                        params: { 'accessToken':localStorage.getItem('token'),
                        page:1,
                        limit:10,
                        phone:this.yw12,
                 
                        }
                        })

                    .then(res=>{
            
                     this.tb1 = res.data.data
                        
                        })
                        .catch(function(){
                        
                        })
                   
             
           },
           cz(){
             
                this.yw12 = ''
             
                this.$axios.get("/s/backend/api/sczj_bussiness_statistic", {
                        params: { 'accessToken':localStorage.getItem('token'),
                        page:1,
                        limit:10,
                
                 
                        }
                        })

                    .then(res=>{
            
                     this.tb1 = res.data.data
                        
                        })
                        .catch(function(){
                        
                        })
                   
           },






     cxd(){
              this.$axios.get("/s/backend/api/dls_bussiness_statistic", {
                        params: { 'accessToken':localStorage.getItem('token'),
                        page:1,
                        limit:10,
                        phone:this.dls1,
                 
                        }
                        })

                    .then(res=>{
            
                     this.tb1 = res.data.data
                        
                        })
                        .catch(function(){
                        
                        })
                   
             
           },
           czd(){
               this.dls1 = ''
       
          
                 this.$axios.get("/s/backend/api/dls_bussiness_statistic", {
        params: { 'accessToken':localStorage.getItem('token'),
          page:1,
                        limit:10
         }
        })

     .then(res=>{
    
          this.tb1 = res.data.data
        
        })
        .catch(function(){
           
        })
           },

  cxd1(){
              this.$axios.get("/s/backend/api/dlsywjl_bussiness_statistic", {
                        params: { 'accessToken':localStorage.getItem('token'),
                        page:1,
                        limit:10,
                        phone:this.dls2,
                 
                        }
                        })

                    .then(res=>{
            
                     this.tb2 = res.data.data
                        
                        })
                        .catch(function(){
                        
                        })
                   
             
           },
           czd1(){
               this.dls2 = ''
       
          
                 this.$axios.get("/s/backend/api/dlsywjl_bussiness_statistic", {
        params: { 'accessToken':localStorage.getItem('token'),
          page:1,
                        limit:10
         }
        })

     .then(res=>{
    
          this.tb2 = res.data.data
        
        })
        .catch(function(){
           
        })
           },



  cxq(){
              this.$axios.get("/s/backend/api/qh_bussiness_statistic", {
                        params: { 'accessToken':localStorage.getItem('token'),
                        page:1,
                        limit:10,
                        phone:this.dls3,
                 
                        }
                        })

                    .then(res=>{
            
                     this.tb1 = res.data.data
                        
                        })
                        .catch(function(){
                        
                        })
                   
             
           },
           czq(){
               this.dls3 = ''
       
          
                 this.$axios.get("/s/backend/api/qh_bussiness_statistic", {
        params: { 'accessToken':localStorage.getItem('token'),
          page:1,
                        limit:10
         }
        })

     .then(res=>{
    
          this.tb1 = res.data.data
        
        })
        .catch(function(){
           
        })
           },




               cx1(){
               if(this.yw21 && this.yw22 ){
            this.$axios.get("/s/backend/api/jszj_bussiness_statistic", {
                        params: { 'accessToken':localStorage.getItem('token') }
                        })

                    .then(res=>{
                    
                   this.tb1=  res.data.data.filter((item,index)=>{
                          return item.name==this.yw21 && item.phone==this.yw22
                        })
                  
                        
                        })
                        .catch(function(){
                        
                        })
               } else  if(this.yw22 && this.yw23 ){
            this.$axios.get("/s/backend/api/jszj_bussiness_statistic", {
                        params: { 'accessToken':localStorage.getItem('token') }
                        })

                    .then(res=>{
                    
                   this.tb1=  res.data.data.filter((item,index)=>{
                          return item.name==this.yw23 && item.phone==this.yw22
                        })
                  
                        
                        })
                        .catch(function(){
                        
                        })
               }else  if(this.yw21 && this.yw23 ){
            this.$axios.get("/s/backend/api/sczj_bussiness_statistic", {
                        params: { 'accessToken':localStorage.getItem('token') }
                        })

                    .then(res=>{
                    
                   this.tb1=  res.data.data.filter((item,index)=>{
                          return item.name==this.yw21 && item.jobnumber==this.yw23
                        })
                  
                        
                        })
                        .catch(function(){
                        
                        })
               } else  if(this.yw21  ){
            this.$axios.get("/s/backend/api/sczj_bussiness_statistic", {
                        params: { 'accessToken':localStorage.getItem('token') }
                        })

                    .then(res=>{
                    
                   this.tb1=  res.data.data.filter((item,index)=>{
                          return item.name==this.yw21
                        })
                  
                        
                        })
                        .catch(function(){
                        
                        })
               }  else  if(this.yw22  ){
            this.$axios.get("/s/backend/api/sczj_bussiness_statistic", {
                        params: { 'accessToken':localStorage.getItem('token') }
                        })

                    .then(res=>{
                    
                   this.tb1=  res.data.data.filter((item,index)=>{
                          return item.phone==this.yw22
                        })
                  
                        
                        })
                        .catch(function(){
                        
                        })
               }  else  if(this.yw23  ){
            this.$axios.get("/s/backend/api/sczj_bussiness_statistic", {
                        params: { 'accessToken':localStorage.getItem('token') }
                        })

                    .then(res=>{
                    
                   this.tb1=  res.data.data.filter((item,index)=>{
                          return item.jobnumber==this.yw23
                        })
                  
                        
                        })
                        .catch(function(){
                        
                        })
               } else{
                     this.$axios.get("/s/backend/api/sczj_bussiness_statistic", {
                        params: { 'accessToken':localStorage.getItem('token') }
                        })

                    .then(res=>{
                    
                   this.tb1=  res.data.data
                        
                        })
                        .catch(function(){
                        
                        })
               }
                   
             
           },
           cz1(){
               this.yw11 = ''
                this.yw12 = ''
                 this.yw13= ''
                 this.$axios.get("/s/backend/api/jszj_bussiness_statistic", {
        params: { 'accessToken':localStorage.getItem('token') }
        })

     .then(res=>{
    
          this.tb1 = res.data.data
        
        })
        .catch(function(){
           
        })
           },

               cx2(){
               if(this.yw31 && this.yw32 ){
            this.$axios.get("/s/backend/api/jszzy_bussiness_statistic", {
                        params: { 'accessToken':localStorage.getItem('token') }
                        })

                    .then(res=>{
                    
                   this.tb1=  res.data.data.filter((item,index)=>{
                          return item.name==this.yw31 && item.phone==this.yw32
                        })
                  
                        
                        })
                        .catch(function(){
                        
                        })
               } else  if(this.yw32 && this.yw33 ){
            this.$axios.get("/s/backend/api/jszzy_bussiness_statistic", {
                        params: { 'accessToken':localStorage.getItem('token') }
                        })

                    .then(res=>{
                    
                   this.tb1=  res.data.data.filter((item,index)=>{
                          return item.name==this.yw33 && item.phone==this.yw32
                        })
                  
                        
                        })
                        .catch(function(){
                        
                        })
               }else  if(this.yw31 && this.yw33 ){
            this.$axios.get("/s/backend/api/jszzy_bussiness_statistic", {
                        params: { 'accessToken':localStorage.getItem('token') }
                        })

                    .then(res=>{
                    
                   this.tb1=  res.data.data.filter((item,index)=>{
                          return item.name==this.yw31 && item.jobnumber==this.yw33
                        })
                  
                        
                        })
                        .catch(function(){
                        
                        })
               } else  if(this.yw31  ){
            this.$axios.get("/s/backend/api/jszzy_bussiness_statistic", {
                        params: { 'accessToken':localStorage.getItem('token') }
                        })

                    .then(res=>{
                    
                   this.tb1=  res.data.data.filter((item,index)=>{
                          return item.name==this.yw31
                        })
                  
                        
                        })
                        .catch(function(){
                        
                        })
               }  else  if(this.yw32  ){
            this.$axios.get("/s/backend/api/jszzy_bussiness_statistic", {
                        params: { 'accessToken':localStorage.getItem('token') }
                        })

                    .then(res=>{
                    
                   this.tb1=  res.data.data.filter((item,index)=>{
                          return item.phone==this.yw32
                        })
                  
                        
                        })
                        .catch(function(){
                        
                        })
               }  else  if(this.yw33  ){
            this.$axios.get("/s/backend/api/jszzy_bussiness_statistic", {
                        params: { 'accessToken':localStorage.getItem('token') }
                        })

                    .then(res=>{
                    
                   this.tb1=  res.data.data.filter((item,index)=>{
                          return item.jobnumber==this.yw33
                        })
                  
                        
                        })
                        .catch(function(){
                        
                        })
               } else{
                     this.$axios.get("/s/backend/api/jszzy_bussiness_statistic", {
                        params: { 'accessToken':localStorage.getItem('token') }
                        })

                    .then(res=>{
                    
                   this.tb1=  res.data.data
                        
                        })
                        .catch(function(){
                        
                        })
               }
                   
             
           },
           cz2(){
               this.yw31 = ''
                this.yw32 = ''
                 this.yw33= ''
                 this.$axios.get("/s/backend/api/jszzy_bussiness_statisti", {
        params: { 'accessToken':localStorage.getItem('token') }
        })

     .then(res=>{
    
          this.tb1 = res.data.data
        
        })
        .catch(function(){
           
        })
           },

             handleSizeChange(val) {
this.pagination.page_size=val

                 this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :this.pagination.page_index,
       limit :val,
       roleid :2  }
        })

     .then(res=>{
    
        this.tableData = res.data.data
        this.pagination.total = res.data.count
        this.jobnumber
        })
        .catch(function(){
           
        })
                 
      },
      handleCurrentChange(val) {
          
       this.pagination.page_index=val
     
          this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :val,
       limit :this.pagination.page_size,
       roleid :2  }
        })

     .then(res=>{
    
        this.tableData = res.data.data
        this.pagination.total = res.data.count
        this.jobnumber
        })
        .catch(function(){
           
        })
      },





   handleSizeChange11(val) {
       
this.pagination22.page_size=val
   this.$axios.get("/s/backend/api/sczj_bussiness_statistic", {
        params: { 'accessToken':localStorage.getItem('token'),
        page :this.pagination22.page_index,
       limit :val,
         }
        })

     .then(res=>{
    
          this.tb1 = res.data.data
        
        })
        .catch(function(){
           
        })

             
                 
      },
      handleCurrentChange11(val) {
     
     this.pagination22.page_index=val
   this.$axios.get("/s/backend/api/sczj_bussiness_statistic", {
        params: { 'accessToken':localStorage.getItem('token'),
        page :val,
        limit :this.pagination22.page_size,
         }
        })

     .then(res=>{
    
          this.tb1 = res.data.data
        
        })
        .catch(function(){
           
        })
      },


      
   handleSizeChange33(val) {
       
this.pagination44.page_size=val
   this.$axios.get("/s/backend/api/dls_bussiness_statistic", {
        params: { 'accessToken':localStorage.getItem('token'),
        page :this.pagination44.page_index,
       limit :val,
         }
        })

     .then(res=>{
    
          this.tb1 = res.data.data
        
        })
        .catch(function(){
           
        })

             
                 
      },
      handleCurrentChange33(val) {
     
     this.pagination44.page_index=val
   this.$axios.get("/s/backend/api/dls_bussiness_statistic", {
        params: { 'accessToken':localStorage.getItem('token'),
        page :val,
        limit :this.pagination44.page_size,
         }
        })

     .then(res=>{
    
          this.tb1 = res.data.data
        
        })
        .catch(function(){
           
        })
      },

  handleSizeChange77(val) {
       
this.pagination44.page_size=val
   this.$axios.get("/s/backend/api/qh_bussiness_statistic", {
        params: { 'accessToken':localStorage.getItem('token'),
        page :this.pagination88.page_index,
       limit :val,
         }
        })

     .then(res=>{
    
          this.tb1 = res.data.data
        
        })
        .catch(function(){
           
        })

             
                 
      },
      handleCurrentChange77(val) {
     
     this.pagination44.page_index=val
   this.$axios.get("/s/backend/api/qh_bussiness_statistic", {
        params: { 'accessToken':localStorage.getItem('token'),
        page :val,
        limit :this.pagination88.page_size,
         }
        })

     .then(res=>{
    
          this.tb1 = res.data.data
        
        })
        .catch(function(){
           
        })
      },

  handleSizeChange55(val) {
       
this.pagination66.page_size=val
   this.$axios.get("/s/backend/api/dlsywjl_bussiness_statistic", {
        params: { 'accessToken':localStorage.getItem('token'),
        page :this.pagination66.page_index,
       limit :val,
         }
        })

     .then(res=>{
    
          this.tb1 = res.data.data
        
        })
        .catch(function(){
           
        })

             
                 
      },
      handleCurrentChange55(val) {
     
     this.pagination44.page_index=val
   this.$axios.get("/s/backend/api/dlsywjl_bussiness_statistic", {
        params: { 'accessToken':localStorage.getItem('token'),
        page :val,
        limit :this.pagination66.page_size,
         }
        })

     .then(res=>{
    
          this.tb1 = res.data.data
        
        })
        .catch(function(){
           
        })
      },






  handleSizeChange2(val) {
this.pagination3.page_size=val

                 this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :this.pagination3.page_index,
       limit :val,
       roleid :11  }
        })

     .then(res=>{
    
        this.tableData = res.data.data
        this.pagination3.total = res.data.count
        this.jobnumber
        })
        .catch(function(){
           
        })
                 
      },
      handleCurrentChange2(val) {
       this.pagination.page_index=val
     
          this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :val,
       limit :this.pagination3.page_size,
       roleid :11  }
        })

     .then(res=>{
    
        this.tableData = res.data.data
        this.pagination.total = res.data.count
        this.jobnumber
        })
        .catch(function(){
           
        })
      },


       handleSizeChange3(val) {
this.pagination4.page_size=val

                 this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :this.pagination4.page_index,
       limit :val,
       roleid :1  }
        })

     .then(res=>{
    
        this.tableData = res.data.data
        this.pagination4.total = res.data.count
        this.jobnumber
        })
        .catch(function(){
           
        })
                 
      },
      handleCurrentChange3(val) {
       this.pagination4.page_index=val
     
          this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :val,
       limit :this.pagination4.page_size,
       roleid :11  }
        })

     .then(res=>{
    
        this.tableData = res.data.data
        this.pagination4.total = res.data.count
        this.jobnumber
        })
        .catch(function(){
           
        })
      },




         handleSizeChange4(val) {
this.pagination5.page_size=val

                 this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :this.pagination5.page_index,
       limit :val,
       roleid :5  }
        })

     .then(res=>{
    
        this.tableData = res.data.data
        this.pagination5.total = res.data.count
        this.jobnumber
        })
        .catch(function(){
           
        })
                 
      },
      handleCurrentChange4(val) {
       this.pagination5.page_index=val
     
          this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :val,
       limit :this.pagination5.page_size,
       roleid :5  }
        })

     .then(res=>{
    
        this.tableData = res.data.data
        this.pagination5.total = res.data.count
        this.jobnumber
        })
        .catch(function(){
           
        })
      },

         handleSizeChange5(val) {
this.pagination5.page_size=val

                 this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :this.pagination6.page_index,
       limit :val,
       roleid :4  }
        })

     .then(res=>{
    
        this.tableData = res.data.data
        this.pagination6.total = res.data.count
        this.jobnumber
        })
        .catch(function(){
           
        })
                 
      },
      handleCurrentChange5(val) {
       this.pagination6.page_index=val
     
          this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :val,
       limit :this.pagination6.page_size,
       roleid :4  }
        })

     .then(res=>{
    
        this.tableData = res.data.data
        this.pagination6.total = res.data.count
        this.jobnumber
        })
        .catch(function(){
           
        })
      },


             handleSizeChange6(val) {
this.pagination7.page_size=val

                 this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :this.pagination7.page_index,
       limit :val,
       roleid :7  }
        })

     .then(res=>{
    
        this.tableData = res.data.data
        this.pagination7.total = res.data.count
        this.jobnumber
        })
        .catch(function(){
           
        })
                 
      },
      handleCurrentChange6(val) {
       this.pagination7.page_index=val
     
          this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :val,
       limit :this.pagination7.page_size,
       roleid :7  }
        })

     .then(res=>{
    
        this.tableData = res.data.data
        this.pagination6.total = res.data.count
        this.jobnumber
        })
        .catch(function(){
           
        })
      },



           handleSizeChange7(val) {
this.pagination7.page_size=val

                 this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :this.pagination8.page_index,
       limit :val,
       roleid :6  }
        })

     .then(res=>{
    
        this.tableData = res.data.data
        this.pagination8.total = res.data.count
        this.jobnumber
        })
        .catch(function(){
           
        })
                 
      },
      handleCurrentChange7(val) {
       this.pagination8.page_index=val
     
          this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :val,
       limit :this.pagination8.page_size,
       roleid :6  }
        })

     .then(res=>{
    
        this.tableData = res.data.data
        this.pagination8.total = res.data.count
        this.jobnumber
        })
        .catch(function(){
           
        })
      },



     handleSizeChange8(val) {
this.pagination9.page_size=val

                 this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :this.pagination8.page_index,
       limit :val,
       roleid :8  }
        })

     .then(res=>{
    
        this.tableData = res.data.data
        this.pagination9.total = res.data.count
        this.jobnumber
        })
        .catch(function(){
           
        })
                 
      },
      handleCurrentChange8(val) {
       this.pagination9.page_index=val
     
          this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :val,
       limit :this.pagination9.page_size,
       roleid :8  }
        })

     .then(res=>{
    
        this.tableData = res.data.data
        this.pagination9.total = res.data.count
        this.jobnumber
        })
        .catch(function(){
           
        })
      },



        handleSizeChange1(val) {
this.pagination1.page_size=val

                 this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :this.pagination1.page_index,
       limit :val,
       roleid :2  }
        })

     .then(res=>{
    
        this.tableData = res.data.data
        this.pagination1.total = res.data.count
        this.jobnumber
        })
        .catch(function(){
           
        })
                 
      },
      handleCurrentChange1(val) {
       this.pagination1.page_index=val
     
          this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :val,
       limit :this.pagination1.page_size,
       roleid :2  }
        })

     .then(res=>{
    
        this.tableData = res.data.data
        this.pagination1.total = res.data.count
        this.jobnumber
        })
        .catch(function(){
           
        })
      },


 ck71(){
               this.input71=''
               this.input72=''
               this.arr = ''
               this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :8 }
        })

     .then(res=>{
    
         this.tableData = res.data.data
 
        })
        .catch(function(){
           
        })
           },
           ck72(){
                  var j = ''
            for(var i =0;i<this.arr.length;i++){
                if(j==''){
                    j=this.arr[i]
                }else{
             j=j+','+this.arr[i]
                }
              
            }
            this.$axios.get("/s/backend/api/user_list", {
                params: { 'accessToken':localStorage.getItem('token'),
            page :1,
            limit :20,
            roleid :8,
            name:this.input71,
            phone:this.input72,
            AreaId:j
            }
        })

     .then(res=>{
    
         this.tableData = res.data.data
  
        })
        .catch(function(){
           
        })
            
           },



  ck61(){
               this.input61=''
               this.input62=''
               this.arr = ''
               this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :6  }
        })

     .then(res=>{
    
         this.tableData = res.data.data
        this.jobnumber
        })
        .catch(function(){
           
        })
           },
           ck62(){
                  var j = ''
            for(var i =0;i<this.arr.length;i++){
                if(j==''){
                    j=this.arr[i]
                }else{
             j=j+','+this.arr[i]
                }
              
            }
    this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :6,
       name:this.input61,
       phone:this.input62,
       AreaId:j
       }
        })

     .then(res=>{
    
         this.tableData = res.data.data
        this.jobnumber
        })
        .catch(function(){
           
        })
            
           },

               ck51(){
               this.input51=''
               this.input52=''
               this.arr = ''
               this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :2  }
        })

     .then(res=>{
    
         this.tableData = res.data.data
        this.jobnumber
        })
        .catch(function(){
           
        })
           },
           ck52(){
                 var j = ''
            for(var i =0;i<this.arr.length;i++){
                if(j==''){
                    j=this.arr[i]
                }else{
             j=j+','+this.arr[i]
                }
              
            }
    this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :2,
       name:this.input51,
       phone:this.input52,
       AreaId:j
       }
        })

     .then(res=>{
    
         this.tableData = res.data.data
        this.jobnumber
        })
        .catch(function(){
           
        })
            
           },


           ck1(){
               this.input2=''
               this.input3=''
               this.arr = ''
               this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :2  }
        })

     .then(res=>{
    
         this.tableData = res.data.data
        this.jobnumber
        })
        .catch(function(){
           
        })
           },
           ck2(){
             
               var j = ''
            for(var i =0;i<this.arr.length;i++){
                if(j==''){
                    j=this.arr[i]
                }else{
             j=j+','+this.arr[i]
                }
              
            }
     
        

    this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :2,
       name:this.input2,
       phone:this.input3,
       AreaId:j
       }
        })

     .then(res=>{
    
         this.tableData = res.data.data
        this.jobnumber
        })
        .catch(function(){
           
        })
            
           },





    ck31(){
               this.input41=''
               this.input42=''
               this.arr = ''
               this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :4  }
        })

     .then(res=>{
    
         this.tableData = res.data.data
        this.jobnumber
        })
        .catch(function(){
           
        })
           },
           ck32(){
                  var j = ''
            for(var i =0;i<this.arr.length;i++){
                if(j==''){
                    j=this.arr[i]
                }else{
             j=j+','+this.arr[i]
                }
              
            }
    this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :4,
       name:this.input41,
       phone:this.input42,
       AreaId:j
       }
        })

     .then(res=>{
    
         this.tableData = res.data.data
        this.jobnumber
        })
        .catch(function(){
           
        })
            
           },




              ck11(){
               this.input31=''
               this.input32=''
               this.arr = ''
               this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :5  }
        })

     .then(res=>{
    
         this.tableData = res.data.data
   
        })
        .catch(function(){
           
        })
           },
           ck22(){
                     var j = ''
            for(var i =0;i<this.arr.length;i++){
                if(j==''){
                    j=this.arr[i]
                }else{
             j=j+','+this.arr[i]
                }
              
            }
    this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :5,
       name:this.input31,
       phone:this.input32,
       AreaId:j
       }
        })

     .then(res=>{
    
         this.tableData = res.data.data
    
        })
        .catch(function(){
           
        })
            
           },



              ck3(){
               this.input22=''
               this.input33=''
               this.arr = ''
               this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :1  }
        })

     .then(res=>{
    
         this.tableData = res.data.data
     
        })
        .catch(function(){
           
        })
           },
           ck4(){
                     var j = ''
            for(var i =0;i<this.arr.length;i++){
                if(j==''){
                    j=this.arr[i]
                }else{
             j=j+','+this.arr[i]
                }
              
            }
    this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :1,
       name:this.input22,
       phone:this.input33,
       AreaId:j
       }
        })

     .then(res=>{
    
         this.tableData = res.data.data
    
        })
        .catch(function(){
           
        })
            
           },


       ck5(){
               this.input4=''
               this.input5=''
               this.arr = ''
               this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :11  }
        })

     .then(res=>{
    
         this.tableData = res.data.data
     
        })
        .catch(function(){
           
        })
           },
           ck6(){
                     var j = ''
            for(var i =0;i<this.arr.length;i++){
                if(j==''){
                    j=this.arr[i]
                }else{
             j=j+','+this.arr[i]
                }
              
            }
    this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :11,
       name:this.input4,
       phone:this.input5,
       AreaId:j
       }
        })

     .then(res=>{
    
         this.tableData = res.data.data
    
        })
        .catch(function(){
           
        })
            
           },




              exportOrderData() {
        var xlsxParam = { raw: true };
        var wb = XLSX.utils.table_to_book(document.querySelector('#out-table'),xlsxParam);
        var wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'array' })
        try {
        FileSaver.saveAs(new Blob([wbout], { type: 'application/octet-stream' }), '市场总监列表.xlsx')
        } catch (e) { if (typeof console !== 'undefined') console.log(e, wbout) }
        return wbout
      },


           exportOrderData1() {
        var xlsxParam = { raw: true };
        var wb = XLSX.utils.table_to_book(document.querySelector('#out-table1'),xlsxParam);
        var wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'array' })
        try {
        FileSaver.saveAs(new Blob([wbout], { type: 'application/octet-stream' }), '市场总监 | 统计.xlsx')
        } catch (e) { if (typeof console !== 'undefined') console.log(e, wbout) }
        return wbout
      },

        exportOrderData2() {
        var xlsxParam = { raw: true };
        var wb = XLSX.utils.table_to_book(document.querySelector('#out-table2'),xlsxParam);
        var wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'array' })
        try {
        FileSaver.saveAs(new Blob([wbout], { type: 'application/octet-stream' }), '市场业务经理.xlsx')
        } catch (e) { if (typeof console !== 'undefined') console.log(e, wbout) }
        return wbout
      },

         exportOrderData3() {
        var xlsxParam = { raw: true };
        var wb = XLSX.utils.table_to_book(document.querySelector('#out-table3'),xlsxParam);
        var wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'array' })
        try {
        FileSaver.saveAs(new Blob([wbout], { type: 'application/octet-stream' }), '市场业务经理.xlsx')
        } catch (e) { if (typeof console !== 'undefined') console.log(e, wbout) }
        return wbout
      },

         exportOrderData4() {
        var xlsxParam = { raw: true };
        var wb = XLSX.utils.table_to_book(document.querySelector('#out-table4'),xlsxParam);
        var wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'array' })
        try {
        FileSaver.saveAs(new Blob([wbout], { type: 'application/octet-stream' }), '技术总监.xlsx')
        } catch (e) { if (typeof console !== 'undefined') console.log(e, wbout) }
        return wbout
      },

        exportOrderData5() {
        var xlsxParam = { raw: true };
        var wb = XLSX.utils.table_to_book(document.querySelector('#out-table4'),xlsxParam);
        var wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'array' })
        try {
        FileSaver.saveAs(new Blob([wbout], { type: 'application/octet-stream' }), '技术总监 | 业务统计.xlsx')
        } catch (e) { if (typeof console !== 'undefined') console.log(e, wbout) }
        return wbout
      },

         exportOrderData6(e,b) {
             console.log(b)
          var xlsxParam = { raw: true };
        var wb = XLSX.utils.table_to_book(document.querySelector('#'+b),xlsxParam);
        var wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'array' })
        try {
        FileSaver.saveAs(new Blob([wbout], { type: 'application/octet-stream' }), e + '.xlsx')
        } catch (e) { if (typeof console !== 'undefined') console.log(e, wbout) }
        return wbout
      },
              changeImage(e,a) {
                
            var file = e.target.files[0]
           
           let data = new FormData();
             this.$axios.get("/s/backend/qn/gettoken", {
                        params: { 'accessToken':localStorage.getItem('token'),
                         filename :file.name
                     }
                        })

                    .then(res=>{
                  
                data.append('token', res.data.uptoken);     
         
                data.append('file', e.target.files[0]);
                data.append('key', e.target.files[0].name);
              
                                                this.$axios({
                                        method: 'post',
                                        url: 'https://upload-z2.qiniup.com/',
                                        data,
                                        headers: {
                                            'Content-Type': 'multipart/form-data'
                                        }
                                    })
                                    .then(res => {
                                        this.producImg = "http://gqtlgj.cn/" + res.data.key;
                                       
                                    })
                                    .catch(err => {
                                      
                                    })

                  
                        })
                        .catch(function(){
                        
                        })

             },
        changeImage1(e,a) {
                
            var file = e.target.files[0]
           
           let data = new FormData();
             this.$axios.get("/s/backend/qn/gettoken", {
                        params: { 'accessToken':localStorage.getItem('token'),
                         filename :file.name
                     }
                        })

                    .then(res=>{
                  
                data.append('token', res.data.uptoken);     
         
                data.append('file', e.target.files[0]);
                data.append('key', e.target.files[0].name);
      
                                                this.$axios({
                                        method: 'post',
                                        url: 'https://upload-z2.qiniup.com/',
                                        data,
                                        headers: {
                                            'Content-Type': 'multipart/form-data'
                                        }
                                    })
                                    .then(res => {
                                        this.producImg1 = "http://gqtlgj.cn/" + res.data.key;
                                    })
                                    .catch(err => {
                                        console.log(err)
                                    })

                  
                        })
                        .catch(function(){
                        
                        })

             },

       finda(index,row){
    this.dialogVisiblefind1a =true
     this.$axios({
                            method: "get",
                            url: "/s/backend/api/user_service_area",
                            params: {
                            accessToken : localStorage.getItem('token'),
            "jobnumber":  row.jobnumber,
                            },
                         
                            }) .then(res=>{
                               this.options1 = res.data.data
                                var arr = []
                              var arr1 = []
                               var arr2 = []
                                 var arr3 = []
                               for (var key in res.data.data) {
                                   arr = arr.concat(key)
                                  arr1 =arr1.concat(res.data.data[key])

                                         
                                }
                              for(var i = 0 ;i<arr1.length;i++){
                                   for (var key1 in arr1[i]) {
                                arr2 =arr2.concat(key1)
                                  arr3 =arr3.concat(arr1[i][key1])       
                                }
                              }
                           this.find111 =arr
                            this.find222 =arr2  
                          this.find333 =arr3
                            })
            .catch(function(){
            
            })  

},
 find(index,row){
    this.dialogVisiblefind1 =true
     this.$axios({
                            method: "get",
                            url: "/s/backend/api/user_service_area",
                            params: {
                            accessToken : localStorage.getItem('token'),
            "jobnumber":  row.jobnumber,
                            },
                         
                            }) .then(res=>{
                               this.options1 = res.data.data
                                var arr = []
                              var arr1 = []
                               var arr2 = []
                                 var arr3 = []
                               for (var key in res.data.data) {
                                   arr = arr.concat(key)
                                  arr1 =arr1.concat(res.data.data[key])

                                         
                                }
                              for(var i = 0 ;i<arr1.length;i++){
                                   for (var key1 in arr1[i]) {
                                arr2 =arr2.concat(key1)
                                  arr3 =arr3.concat(arr1[i][key1])       
                                }
                              }
                           this.find1 =arr
                            this.find2 =arr2  
                          this.find3 =arr3
                            })
            .catch(function(){
            
            })  

},
find1a(index,row){
    this.dialogVisiblefind2a =true
     this.$axios({
                            method: "get",
                            url: "/s/backend/api/user_service_area",
                            params: {
                            accessToken : localStorage.getItem('token'),
            "jobnumber":  row.jobnumber,
                            },
                         
                            }) .then(res=>{
                               this.options1 = res.data.data
                                var arr = []
                               for( var i = 0;i++;i<res.data.data.length){
                                   arr = arr.push(res.data.data[i][0])
                               }
                          
                            })
            .catch(function(){
            
            })  

},
find1s(index,row){
    this.dialogVisiblefind2 =true
     this.$axios({
                            method: "get",
                            url: "/s/backend/api/user_service_area",
                            params: {
                            accessToken : localStorage.getItem('token'),
            "jobnumber":  row.jobnumber,
                            },
                         
                            }) .then(res=>{
                               this.options1 = res.data.data
                                var arr = []
                              var arr1 = []
                               var arr2 = []
                                 var arr3 = []
                               for (var key in res.data.data) {
                                   arr = arr.concat(key)
                                  arr1 =arr1.concat(res.data.data[key])

                                         
                                }
                              for(var i = 0 ;i<arr1.length;i++){
                                   for (var key1 in arr1[i]) {
                                arr2 =arr2.concat(key1)
                                  arr3 =arr3.concat(arr1[i][key1])       
                                }
                              }
                           this.find1q =arr
                            this.find2q =arr2  
                          this.find3q =arr3
                            })
            .catch(function(){
            
            })  

},
find33s(index,row){
    this.dialogVisiblefind3 =true
     this.$axios({
                            method: "get",
                            url: "/s/backend/api/user_service_area",
                            params: {
                            accessToken : localStorage.getItem('token'),
            "jobnumber":  row.jobnumber,
                            },
                         
                            }) .then(res=>{
                               this.options1 = res.data.data
                                var arr = []
                              var arr1 = []
                               var arr2 = []
                                 var arr3 = []
                               for (var key in res.data.data) {
                                   arr = arr.concat(key)
                                  arr1 =arr1.concat(res.data.data[key])

                                         
                                }
                              for(var i = 0 ;i<arr1.length;i++){
                                   for (var key1 in arr1[i]) {
                                arr2 =arr2.concat(key1)
                                  arr3 =arr3.concat(arr1[i][key1])       
                                }
                              }
                           this.find1qq =arr
                            this.find2qq =arr2  
                          this.find3qq =arr3
                            })
            .catch(function(){
            
            })  

},


find3c(index,row){
    this.dialogVisiblefind3c =true
  this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :4,
       name:row.parent,

       }
        })

     .then(res=>{
      
        this.$axios({
                            method: "get",
                            url: "/s/backend/api/user_service_area",
                            params: {
                            accessToken : localStorage.getItem('token'),
            "jobnumber": res.data.data[0].jobnumber,
                            },
                         
                            }) .then(res=>{
                               this.options1 = res.data.data
                                var arr = []
                               for( var i = 0;i++;i<res.data.data.length){
                                   arr = arr.push(res.data.data[i][0])
                               }
                          
                            })
            .catch(function(){
            
            })  
        })
        .catch(function(){
           
        })
            


},

find3b(index,row){
    this.dialogVisiblefind3b =true
 this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :4,
       name:row.parent,

       }
        })

     .then(res=>{
      
        this.$axios({
                            method: "get",
                            url: "/s/backend/api/user_service_area",
                            params: {
                            accessToken : localStorage.getItem('token'),
            "jobnumber": res.data.data[0].jobnumber,
                            },
                         
                            }) .then(res=>{
                               this.options1 = res.data.data
                                var arr = []
                               for( var i = 0;i++;i<res.data.data.length){
                                   arr = arr.push(res.data.data[i][0])
                               }
                          
                            })
            .catch(function(){
            
            })  
        })
        .catch(function(){
           
        })
            

},

find3a(index,row){
    this.dialogVisiblefind3a =true
   
  this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :4,
       name:row.parent,

       }
        })

     .then(res=>{
      
        this.$axios({
                            method: "get",
                            url: "/s/backend/api/user_service_area",
                            params: {
                            accessToken : localStorage.getItem('token'),
            "jobnumber": res.data.data[0].jobnumber,
                            },
                         
                            }) .then(res=>{
                               this.options1 = res.data.data
                                var arr = []
                               for( var i = 0;i++;i<res.data.data.length){
                                   arr = arr.push(res.data.data[i][0])
                               }
                          
                            })
            .catch(function(){
            
            })  
        })
        .catch(function(){
           
        })
            


  

},
              a11(){
               

                  this.$confirm('此操作将修改昵称, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$message({
            type: 'success',
            message: '修改成功!'
          });
            this.$axios({
                        method: "post",
                        url: "/s/backend/api/userupdate",
                        params: {
                        accessToken:localStorage.getItem('token'),
                   
                        },
                        data:{
                            "jobnumber": localStorage.getItem('jobnumber'),
                            "name": this.info.name
                        }
                        }) .then(res=>{
                        
                           
                          var cardNumObj = { arealist:res.data.data }
                           var cardNumObj1 = { yyzcurl:'' }
                          var assignObj =  Object.assign(this.info,cardNumObj);
                          var assignObj1 = Object.assign(assignObj,cardNumObj1)
                                        this.$axios({
                                        method: "post",
                                        url: "/s/backend/api/userupdate",
                                        params: {
                                        accessToken : localStorage.getItem('token')
                                        },
                                        data:assignObj1,
                                        }) .then(res=>{
                                            this.dialogFormVisible = false
                                            this.$message({
                                message:'修改成功',
                                type: 'success'
                                    });
                        })
        .catch(function(){
           
        })  
                  
                        })
        .catch(function(){
           
        })  


        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消修改'
          });          
        });
      



 

      
       
        },
        a22(){
            this.password2=true
         
              
        },
        a33(){

              this.$confirm('此操作将修改密码, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
         
               if(this.password ==''){
                this.$message({
                   message:'请输入新密码后点击修改',
                  type: 'success'
                     }); 
            }else{
                    this.$axios({
                            method: "post",
                            url: "/s/backend/api/changepass",
                            params: {
                            accessToken : localStorage.getItem('token')
                            },
                            data:{
                        "jobnumber":  localStorage.getItem('jobnumber'),
                        "newpassword": this.password,
                        "oldpassword": this.password3
                        },
                            }) .then(res=>{
                            
                            if(res.data.code == 0){
                                    this.dialogFormVisible = false
                                    this.$message({
                                message:'修改成功',
                                type: 'success'
                                    });
                            }else{
                                    this.dialogFormVisible = false
                                    this.$message({
                                        message:res.data.msg,
                                        type: 'success'
                                            });
                            }
                            })
            .catch(function(){
            
            })  
            }

        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消修改'
          });          
        });







            
        },
             tuichu(){
            this.dialogFormVisible = false;
            window.localStorage.removeItem('token')
                     this.$router.push({ name: 'login'})
        },
           changeProvinceCity(val) { 
               let arr = []
        
         
                for(var i =0;i<val.length;i++){
             arr = arr.concat(val[i][2])
                }
          this.arr =arr
              
},
tianjia(){},
    tianjia1(){
           if(!(/^[1-9]{1}[0-9]{14}$|^[1-9]{1}[0-9]{16}([0-9]|[xX])$/.test(this.form1.id))){

    this.$message({
                        message:'身份证号有误，请重新输入',
                        type: 'info'
                            }); 
}
       
           else  if(this.form1.key_2!=='' && this.form1.phone!=='' && this.form1.name !=='' && this.arr !== '' && this.form1.worktime!=='' &&this.form1.id!==''&&this.producImg !==''&&this.producImg1!==''){
                 console.log()     
                          var d1 =     this.form1.id.substring(5,9)
                                   var d2 =  this.form1.id.substring(10,12)
                                   var d3 = d1+'-'+d2
               this.$axios({
                        method: "post",
                        url: "/s/backend/api/useradd",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{ 
                             "birthday":d3,
                        "zjzpurl":'',
                        "sex":'',
                        "address":'',
                        "worktime":this.form1.worktime,
                        "arealist": this.arr,
                        "id": this.form1.id,
                          "idurl1": this.producImg,
                        "idurl2":this.producImg1,
                        "key_1": "string",
                        "key_2": this.form1.key_2,
                        "key_3": "string",
                        "key_4": "string",
                        "name": this.form1.name,
                        "parent": localStorage.getItem('jobnumber'),
                        "password": 123456,
                        "phone": this.form1.phone,
                        "roleid": 2,
                        "yyzcurl": "string",
                        "yyzz": "string",
                        "zsurl": "string"
                        }
                        }) .then(res=>{
                          
                            
                            if(res.data.code==0){
                                this.form1.name='',
                                this.form1.phone='',
                                this.form1.namregione='',
                                this.form1.namkey_2e='',
                                this.form1.id='',
                                this.form1.worktime='',
                                this.producImg='',
                                this.producImg1='',
                                      
                                       this.$message({
                        message:res.data.msg,
                        type: 'success'
                            });
                            this.dialogFormVisible1 = false
                            this.arr= ''
                                this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :2  }
        })

     .then(res=>{
    
         this.tableData = res.data.data
 
        })
        .catch(function(){
           
        })

                            }else{
                                  
                                
          
       
     
                                     this.$message({
                        message:res.data.msg,
                        type: 'success'
                            });
                            }

                        })
                        
        .catch(function(){
           
        })  
        }else{
             this.$message({
                        message:'请填写完整表单',
                        type: 'success'
                            }); 
        }
           },
tianjia2(){},


 tianjia3(){
     if(!(/^[1-9]{1}[0-9]{14}$|^[1-9]{1}[0-9]{16}([0-9]|[xX])$/.test(this.form3.id))){

    this.$message({
                        message:'身份证号有误，请重新输入',
                        type: 'info'
                            }); 
}
       
           else 
                if(this.form3.key_2!=='' && this.form3.phone!=='' && this.form3.name !=='' && this.arr !== '' && this.form3.worktime!=='' &&this.form3.id!==''&&this.producImg !==''&&this.producImg1!==''){
                 console.log()     
                          var d1 =     this.form3.id.substring(5,9)
                                   var d2 =  this.form3.id.substring(10,12)
                                   var d3 = d1+'-'+d2
               this.$axios({
                        method: "post",
                        url: "/s/backend/api/useradd",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{ 
                             "birthday":d3,
                        "zjzpurl":'',
                        "sex":'',
                        "address":'',
                        "worktime":this.form3.worktime,
                        "arealist": this.arr,
                        "id": this.form3.id,
                          "idurl1": this.producImg,
                        "idurl2":this.producImg1,
                        "key_1": "string",
                        "key_2": this.form3.key_2,
                        "key_3": "string",
                        "key_4": "string",
                        "name": this.form3.name,
                        "parent": localStorage.getItem('jobnumber'),
                        "password": 123456,
                        "phone": this.form3.phone,
                        "roleid": 1,
                        "yyzcurl": "string",
                        "yyzz": "string",
                        "zsurl": "string"
                        }
                        }) .then(res=>{
                          
                            
                            if(res.data.code==0){
                                this.form3.id='',
                                 this.form3.name=''
                                this.form3.phone=''
                                this.producImg1='',
                                this.producImg='',
                                this.form3.key_2=''
                                       this.$message({
                        message:res.data.msg,
                        type: 'success'
                            });
                            this.dialogFormVisible33 = false
                            this.arr= ''
                                this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :30,
       roleid :1 }
        })

     .then(res=>{
    
         this.tableData = res.data.data
 
        })
        .catch(function(){
           
        })

                            }else{
                                     this.$message({
                        message:res.data.msg,
                        type: 'success'
                            });
                            }

                        })
                        
        .catch(function(){
           
        })  
        }else{
             this.$message({
                        message:'请填写完整表单',
                        type: 'success'
                            }); 
        }
           },






show2(){
    
    this.key1=1
     this.$axios.get("/s/backend/api/sczj_bussiness_statistic", {
        params: { 'accessToken':localStorage.getItem('token'),
        
        page:1,
        limit:10}
        })

     .then(res=>{
    
          this.tb1 = res.data.data
            this.pagination22.total = res.data.count
        })
        .catch(function(){
           
        })


},

show22(){this.key1=1

            this.$axios.get("/s/backend/api/dlsywjl_bussiness_statistic", {
        params: { 'accessToken':localStorage.getItem('token') }
        })

     .then(res=>{
    
          this.tb2 = res.data.data
            this.pagination66.total = res.data.count[0].count
        })
        .catch(function(){
           
        })


},


show33(){this.key1=1;

            this.$axios.get("/s/backend/api/jszj_bussiness_statistic", {
        params: { 'accessToken':localStorage.getItem('token') }
        })

     .then(res=>{
    
          this.tb1 = res.data.data
 
        })
        .catch(function(){
           
        })


},



show44(){this.key1=1

            this.$axios.get("/s/backend/api/jszzy_bussiness_statistic", {
        params: { 'accessToken':localStorage.getItem('token') }
        })

     .then(res=>{
    
          this.tb1 = res.data.data
 
        })
        .catch(function(){
           
        })


},


show55(){
    this.key1=1

       this.$axios.get("/s/backend/api/dls_bussiness_statistic", {
        params: { 'accessToken':localStorage.getItem('token') ,
        page:1,
        limit:10,
        
        }
        })

     .then(res=>{
         this.pagination44.total = res.data.count
          this.tb1 = res.data.data
 
        })
        .catch(function(){
           
        })


},
show1(){this.key1=0},
 shows(){  this.key1=0},      
 


shows1(){
    
    this.key1=1
     this.$axios.get("/s/backend/api/qh_bussiness_statistic", {
        params: { 'accessToken':localStorage.getItem('token') }
        })

     .then(res=>{
       this.pagination88.total = res.data.count
          this.tb1 = res.data.data
        
        })
        .catch(function(){
           
        })


},

handleClick(tab, event) {
          if(tab.name == 'first'){
              this.tab1()
          }else if( tab.name == 'second')
          {
             this.tab2() 
          }else if( tab.name == 'third')
          {
             this.tab3() 
          }else if( tab.name == 'fourth')
          {
             this.tab4() 
          }else if( tab.name == 'five')
          {
             this.tab5() 
          }else if( tab.name == 'six')
          {
             this.tab6() 
          }else if( tab.name == 'seven')
          {
             this.tab7() 
          }else if( tab.name == 'eight')
          {
             this.tab8() 
          }
      },
        //
         tab1(){
             this.key1=0
            this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :10,
       roleid :2  }
        })

     .then(res=>{
    
          this.tableData = res.data.data
 
        })
        .catch(function(){
           
        })
        },
        tab2(){
             this.key1=0
            this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :10,
       roleid :11  }
        })

     .then(res=>{
    
          this.tableData = res.data.data
            this.pagination3.total = res.data.count
        })
        .catch(function(){
           
        })
        },

   tab3(){
        this.key1=0
            this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :1  }
        })

     .then(res=>{
    
         this.tableData = res.data.data
   this.pagination4.total = res.data.count
        })
        .catch(function(){
           
        })
        },

 tab4(){ this.key1=0
            this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :10,
       roleid :5  }
        })

     .then(res=>{
    
          this.tableData = res.data.data
 this.pagination5.total = res.data.count
        })
        .catch(function(){
           
        })
        },
 tab5(){ this.key1=0
            this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :4  }
        })

     .then(res=>{
    
        this.tableData = res.data.data
  this.pagination6.total = res.data.count
        })
        .catch(function(){
           
        })



  this.$axios.get("/s/backend/api/dls_bussiness_statistic", {
        params: { 'accessToken':localStorage.getItem('token'),
         page:1,
        limit:10,
      }
        })

     .then(res=>{
    
        this.tb1 = res.data.data
    
        })
        .catch(function(){
           
        })


        },
 tab6(){ this.key1=0
 this.tb1=''  
            this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :7  }
        })

     .then(res=>{
    
        this.tableData = res.data.data
  this.pagination7.total = res.data.count
        })
        .catch(function(){
           
        })



          this.$axios.get("/s/backend/api/dlsywjl_bussiness_statistic", {
        params: { 'accessToken':localStorage.getItem('token'),
      }
        })

     .then(res=>{
         this.pagination66.total =res.data.count[0].count
        this.tb1 = res.data.data
        })
        .catch(function(){
           
        })
        },

         tab7(){ this.key1=0
            this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :6  }
        })

     .then(res=>{
    
         this.tableData = res.data.data
            this.pagination8.total = res.data.count
        })
        .catch(function(){
           
        })
        },

         tab8(){ this.key1=0
            this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :8 }
        })

     .then(res=>{
     this.pagination9.total = res.data.count
         this.tableData = res.data.data
 
        })
        .catch(function(){
           
        })
        },
      //
           tiania(){
               console.log(this.form1.region)
            
                this.$axios({
                        method: "post",
                        url: "/s/backend/api/useradd",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{ 
                        "arealist": [
                            
                        ],
                        "id": "string",
                        "idurl1": "string",
                        "idurl2": "string",
                        "key_1": "string",
                        "key_2": "string",
                        "key_3": "string",
                        "key_4": "string",
                        "name": this.form1.name,
                        "parent": 0,
                        "password": 123456,
                        "phone": this.form1.phone,
                        "roleid": this.form1.region,
                        "yyzcurl": "string",
                        "yyzz": "string",
                        "zsurl": "string"
                        }
                        }) .then(res=>{
                                 this.$message({
                        message:res.data.msg,
                        type: 'success'
                            });
                                if(res.data.code==0){
                                       this.dialogFormVisible3=false
                                          this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :10  }
        })

     .then(res=>{
    
         var data1 = res.data.data
   this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :20,
       roleid :9  }
        })

     .then(res=>{
     
     this.tableData=data1.concat(this.data2)

        })
        .catch(function(){
           
        })
        })
        .catch(function(){
           
        })
    
                                }
                        })
        .catch(function(){
           
        })  
           },
          handleEdit(index,row){
              this.$axios({
                        method: "post",
                        url: "/s/backend/api/userban",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{ "flag": 1,
                         "jobnumber":row.jobnumber},
                        }) .then(res=>{
                    this.$axios.get("/s/backend/api/user_list", {
                            params: { 'accessToken':localStorage.getItem('token'),
                        page :1,
                        limit :20,
                        roleid :2  }
                            })

                        .then(res=>{
                        
                            this.tableData = res.data.data
                    
                            })
                            .catch(function(){
                            
                            })
                            if(res.data.code==0){

                            }else{
                        this.$message({
                   message:res.data.msg,
                  type: 'success'
                     });
                            }
                                
                        })
        .catch(function(){
           
        })  
          },

    sc(index,row){
         this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
              this.$axios({
                        method: "post",
                        url: "/s/backend/api/deleteuser",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{
                         "jobnumber":row.jobnumber},
                        }) .then(res=>{
                            if(res.data.code == 0){
                                  this.$axios.get("/s/backend/api/user_list", {
                            params: { 'accessToken':localStorage.getItem('token'),
                        page :1,
                        limit :10,
                        roleid :2  }
                            })

                        .then(res=>{
                        
                            this.tableData = res.data.data
                    
                            })
                            .catch(function(){
                            
                            })
                          this.$message({
            type: 'success',
            message: '删除成功!'
          });
                            }else{
                                  this.$message({
            type: 'error',
            message: res.data.msg
          }); 
                            }

                  
                          
                                
                        })
        .catch(function(){
           
        })  
         
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });



                
          },
              sc1(index,row){
         this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
              this.$axios({
                        method: "post",
                        url: "/s/backend/api/deleteuser",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{
                         "jobnumber":row.jobnumber},
                        }) .then(res=>{
                            if(res.data.code ==0){
                                  this.$axios.get("/s/backend/api/user_list", {
                            params: { 'accessToken':localStorage.getItem('token'),
                        page :1,
                        limit :10,
                        roleid :11  }
                            })

                        .then(res=>{
                        
                            this.tableData = res.data.data
                    
                            })
                            .catch(function(){
                            
                            })
                            this.$message({
            type: 'success',
            message: '删除成功!'
          });
                            }else{
                                      this.$message({
            type: 'error',
            message: res.data.msg
          });  
                            }
                  
                          
                                
                        })
        .catch(function(){
           
        })  
       
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });



                
          },

  sc2(index,row){
         this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
              this.$axios({
                        method: "post",
                        url: "/s/backend/api/deleteuser",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{
                         "jobnumber":row.jobnumber},
                        }) .then(res=>{
                            if(res.data.code==0){
  this.$axios.get("/s/backend/api/user_list", {
                            params: { 'accessToken':localStorage.getItem('token'),
                        page :1,
                        limit :10,
                        roleid :1  }
                            })

                        .then(res=>{
                        
                            this.tableData = res.data.data
                    
                            })
                            .catch(function(){
                            
                            })
                               this.$message({
            type: 'success',
            message: '删除成功!'
          });
                            }else{
                                     this.$message({
            type: 'error',
            message: res.data.msg
          });
                            }
                  
                         
                          
                                
                        })
        .catch(function(){
           
        })  
       
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });



                
          },
            sc3(index,row){
         this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
              this.$axios({
                        method: "post",
                        url: "/s/backend/api/deleteuser",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{
                         "jobnumber":row.jobnumber},
                        }) .then(res=>{

                            if(res.data.code==0){
                                 this.$axios.get("/s/backend/api/user_list", {
                            params: { 'accessToken':localStorage.getItem('token'),
                        page :1,
                        limit :10,
                        roleid :5  }
                            })

                        .then(res=>{
                        
                            this.tableData = res.data.data
                    
                            })
                            .catch(function(){
                            
                            })
                             this.$message({
            type: 'success',
            message: '删除成功!'
          });
                            }else{
                                 this.$message({
            type: 'error',
            message: res.data.msg
          });
                            }
                   
                         
                          
                                
                        })
        .catch(function(){
           
        })  
         
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });



                
          },
             sc4(index,row){
         this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
              this.$axios({
                        method: "post",
                        url: "/s/backend/api/deleteuser",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{
                         "jobnumber":row.jobnumber},
                        }) .then(res=>{
                            if(res.data.code ==0){
                                          this.$axios.get("/s/backend/api/user_list", {
                            params: { 'accessToken':localStorage.getItem('token'),
                        page :1,
                        limit :10,
                        roleid :4  }
                            })

                        .then(res=>{
                        
                            this.tableData = res.data.data
                    
                            })
                            .catch(function(){
                            
                            })
                             this.$message({
            type: 'success',
            message: '删除成功!'
          });
                            }else{
                                  this.$message({
            type: 'error',
            message: res.data.msg
          });
                            }

              
                         
                          
                                
                        })
        .catch(function(){
           
        })  
         
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });



                
          },
            sc5(index,row){
         this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
              this.$axios({
                        method: "post",
                        url: "/s/backend/api/deleteuser",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{
                         "jobnumber":row.jobnumber},
                        }) .then(res=>{
                               if(res.data.code==0){
                                this.$axios.get("/s/backend/api/user_list", {
                     
                            params: { 'accessToken':localStorage.getItem('token'),
                        page :1,
                        limit :10,
                        roleid :7  }
                            })

                        .then(res=>{
                        
                            this.tableData = res.data.data
                    
                            })
                            .catch(function(){
                            
                            })
                             this.$message({
            type: 'success',
            message: '删除成功!'
          });
                        }else{
                                this.$message({
            type: 'error',
            message: res.data.msg
          });
                        }
                
                         
                          
                                
                        })
        .catch(function(){
           
        })  
         
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });



                
          },

              sc6(index,row){
         this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
              this.$axios({
                        method: "post",
                        url: "/s/backend/api/deleteuser",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{
                         "jobnumber":row.jobnumber},
                        }) .then(res=>{
                            if(res.data.code==0){
                                         this.$axios.get("/s/backend/api/user_list", {
                            params: { 'accessToken':localStorage.getItem('token'),
                        page :1,
                        limit :10,
                        roleid :6  }
                            })

                        .then(res=>{
                        
                            this.tableData = res.data.data
                    
                            })
                            .catch(function(){
                            
                            })
                              this.$message({
            type: 'success',
            message: '删除成功!'
          });
                            }else{
                                    this.$message({
            type: 'error',
            message: res.data.msg
          });
                            }
           
                         
                          
                                
                        })
        .catch(function(){
           
        })  
        
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });



                
          },
               sc7(index,row){
         this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
              this.$axios({
                        method: "post",
                        url: "/s/backend/api/deleteuser",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{
                         "jobnumber":row.jobnumber},
                        }) .then(res=>{
                            if(res.data.code==0){
                                 this.$axios.get("/s/backend/api/user_list", {
                            params: { 'accessToken':localStorage.getItem('token'),
                        page :1,
                        limit :10,
                        roleid :8  }
                            })

                        .then(res=>{
                        
                            this.tableData = res.data.data
                    
                            })
                            .catch(function(){
                            
                            })
                            this.$message({
            type: 'success',
            message: '删除成功!'
          });
                            }else{
                                this.$message({
            type: 'error',
            message: res.data.msg
          });
                            }
                   
                         
                          
                                
                        })
        .catch(function(){
           
        })  
          
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });



                
          },
          
             handleEdit1(index,row){
                  this.$axios({
                        method: "post",
                        url: "/s/backend/api/userban",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{ "flag": 0,
                         "jobnumber":row.jobnumber},
                        }) .then(res=>{
                    this.$axios.get("/s/backend/api/user_list", {
                            params: { 'accessToken':localStorage.getItem('token'),
                        page :1,
                        limit :20,
                        roleid :2  }
                            })

                        .then(res=>{
                        
                            this.tableData = res.data.data
                    
                            })
                            .catch(function(){
                            
                            })
                            if(res.data.code==0){

                            }else{
                        this.$message({
                   message:res.data.msg,
                  type: 'success'
                     });
                            }
                                
                        })
        .catch(function(){
           
        })  
          },





            handleEdit2(index,row){
              this.$axios({
                        method: "post",
                        url: "/s/backend/api/userban",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{ "flag": 1,
                         "jobnumber":row.jobnumber},
                        }) .then(res=>{
                    this.$axios.get("/s/backend/api/user_list", {
                            params: { 'accessToken':localStorage.getItem('token'),
                        page :1,
                        limit :20,
                        roleid :11  }
                            })

                        .then(res=>{
                        
                            this.tableData = res.data.data
                    
                            })
                            .catch(function(){
                            
                            })
                            if(res.data.code==0){

                            }else{
                        this.$message({
                   message:res.data.msg,
                  type: 'success'
                     });
                            }
                                
                        })
        .catch(function(){
           
        })  
          },
             handleEdit3(index,row){
                  this.$axios({
                        method: "post",
                        url: "/s/backend/api/userban",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{ "flag": 0,
                         "jobnumber":row.jobnumber},
                        }) .then(res=>{
                    this.$axios.get("/s/backend/api/user_list", {
                            params: { 'accessToken':localStorage.getItem('token'),
                        page :1,
                        limit :20,
                        roleid :11  }
                            })

                        .then(res=>{
                        
                            this.tableData = res.data.data
                    
                            })
                            .catch(function(){
                            
                            })
                            if(res.data.code==0){

                            }else{
                        this.$message({
                   message:res.data.msg,
                  type: 'success'
                     });
                            }
                                
                        })
        .catch(function(){
           
        })  
          },








    handleEdit4(index,row){
              this.$axios({
                        method: "post",
                        url: "/s/backend/api/userban",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{ "flag": 1,
                         "jobnumber":row.jobnumber},
                        }) .then(res=>{
                    this.$axios.get("/s/backend/api/user_list", {
                            params: { 'accessToken':localStorage.getItem('token'),
                        page :1,
                        limit :20,
                        roleid :1  }
                            })

                        .then(res=>{
                        
                            this.tableData = res.data.data
                    
                            })
                            .catch(function(){
                            
                            })
                            if(res.data.code==0){

                            }else{
                        this.$message({
                   message:res.data.msg,
                  type: 'success'
                     });
                            }
                                
                        })
        .catch(function(){
           
        })  
          },
             handleEdit5(index,row){
                  this.$axios({
                        method: "post",
                        url: "/s/backend/api/userban",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{ "flag": 0,
                         "jobnumber":row.jobnumber},
                        }) .then(res=>{
                    this.$axios.get("/s/backend/api/user_list", {
                            params: { 'accessToken':localStorage.getItem('token'),
                        page :1,
                        limit :20,
                        roleid :1  }
                            })

                        .then(res=>{
                        
                            this.tableData = res.data.data
                    
                            })
                            .catch(function(){
                            
                            })
                            if(res.data.code==0){

                            }else{
                        this.$message({
                   message:res.data.msg,
                  type: 'success'
                     });
                            }
                                
                        })
        .catch(function(){
           
        })  
          },





    handleEdit6(index,row){
              this.$axios({
                        method: "post",
                        url: "/s/backend/api/userban",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{ "flag": 1,
                         "jobnumber":row.jobnumber},
                        }) .then(res=>{
                    this.$axios.get("/s/backend/api/user_list", {
                            params: { 'accessToken':localStorage.getItem('token'),
                        page :1,
                        limit :20,
                        roleid :5 }
                            })

                        .then(res=>{
                        
                            this.tableData = res.data.data
                    
                            })
                            .catch(function(){
                            
                            })
                            if(res.data.code==0){

                            }else{
                        this.$message({
                   message:res.data.msg,
                  type: 'success'
                     });
                            }
                                
                        })
        .catch(function(){
           
        })  
          },
             handleEdit7(index,row){
                  this.$axios({
                        method: "post",
                        url: "/s/backend/api/userban",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{ "flag": 0,
                         "jobnumber":row.jobnumber},
                        }) .then(res=>{
                    this.$axios.get("/s/backend/api/user_list", {
                            params: { 'accessToken':localStorage.getItem('token'),
                        page :1,
                        limit :20,
                        roleid :5  }
                            })

                        .then(res=>{
                        
                            this.tableData = res.data.data
                    
                            })
                            .catch(function(){
                            
                            })
                            if(res.data.code==0){

                            }else{
                        this.$message({
                   message:res.data.msg,
                  type: 'success'
                     });
                            }
                                
                        })
        .catch(function(){
           
        })  
          },


    handleEdit8(index,row){
              this.$axios({
                        method: "post",
                        url: "/s/backend/api/userban",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{ "flag": 1,
                         "jobnumber":row.jobnumber},
                        }) .then(res=>{
                    this.$axios.get("/s/backend/api/user_list", {
                            params: { 'accessToken':localStorage.getItem('token'),
                        page :1,
                        limit :20,
                        roleid :4  }
                            })

                        .then(res=>{
                        
                            this.tableData = res.data.data
                    
                            })
                            .catch(function(){
                            
                            })
                            if(res.data.code==0){

                            }else{
                        this.$message({
                   message:res.data.msg,
                  type: 'success'
                     });
                            }
                                
                        })
        .catch(function(){
           
        })  
          },
             handleEdit9(index,row){
                  this.$axios({
                        method: "post",
                        url: "/s/backend/api/userban",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{ "flag": 0,
                         "jobnumber":row.jobnumber},
                        }) .then(res=>{
                    this.$axios.get("/s/backend/api/user_list", {
                            params: { 'accessToken':localStorage.getItem('token'),
                        page :1,
                        limit :20,
                        roleid :4  }
                            })

                        .then(res=>{
                        
                            this.tableData = res.data.data
                    
                            })
                            .catch(function(){
                            
                            })
                            if(res.data.code==0){

                            }else{
                        this.$message({
                   message:res.data.msg,
                  type: 'success'
                     });
                            }
                                
                        })
        .catch(function(){
           
        })  
          },

    handleEdit10(index,row){
              this.$axios({
                        method: "post",
                        url: "/s/backend/api/userban",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{ "flag": 1,
                         "jobnumber":row.jobnumber},
                        }) .then(res=>{
                    this.$axios.get("/s/backend/api/user_list", {
                            params: { 'accessToken':localStorage.getItem('token'),
                        page :1,
                        limit :20,
                        roleid :7 }
                            })

                        .then(res=>{
                        
                            this.tableData = res.data.data
                    
                            })
                            .catch(function(){
                            
                            })
                            if(res.data.code==0){

                            }else{
                        this.$message({
                   message:res.data.msg,
                  type: 'success'
                     });
                            }
                                
                        })
        .catch(function(){
           
        })  
          },
             handleEdit11(index,row){
                  this.$axios({
                        method: "post",
                        url: "/s/backend/api/userban",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{ "flag": 0,
                         "jobnumber":row.jobnumber},
                        }) .then(res=>{
                    this.$axios.get("/s/backend/api/user_list", {
                            params: { 'accessToken':localStorage.getItem('token'),
                        page :1,
                        limit :20,
                        roleid :7 }
                            })

                        .then(res=>{
                        
                            this.tableData = res.data.data
                    
                            })
                            .catch(function(){
                            
                            })
                            if(res.data.code==0){

                            }else{
                        this.$message({
                   message:res.data.msg,
                  type: 'success'
                     });
                            }
                                
                        })
        .catch(function(){
           
        })  
          },


            handleEdit12(index,row){
              this.$axios({
                        method: "post",
                        url: "/s/backend/api/userban",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{ "flag": 1,
                         "jobnumber":row.jobnumber},
                        }) .then(res=>{
                    this.$axios.get("/s/backend/api/user_list", {
                            params: { 'accessToken':localStorage.getItem('token'),
                        page :1,
                        limit :20,
                        roleid :6  }
                            })

                        .then(res=>{
                        
                            this.tableData = res.data.data
                    
                            })
                            .catch(function(){
                            
                            })
                            if(res.data.code==0){

                            }else{
                        this.$message({
                   message:res.data.msg,
                  type: 'success'
                     });
                            }
                                
                        })
        .catch(function(){
           
        })  
          },
             handleEdit13(index,row){
                  this.$axios({
                        method: "post",
                        url: "/s/backend/api/userban",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{ "flag": 0,
                         "jobnumber":row.jobnumber},
                        }) .then(res=>{
                    this.$axios.get("/s/backend/api/user_list", {
                            params: { 'accessToken':localStorage.getItem('token'),
                        page :1,
                        limit :20,
                        roleid :6  }
                            })

                        .then(res=>{
                        
                            this.tableData = res.data.data
                    
                            })
                            .catch(function(){
                            
                            })
                            if(res.data.code==0){

                            }else{
                        this.$message({
                   message:res.data.msg,
                  type: 'success'
                     });
                            }
                                
                        })
        .catch(function(){
           
        })  
          },

    handleEdit14(index,row){
              this.$axios({
                        method: "post",
                        url: "/s/backend/api/userban",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{ "flag": 1,
                         "jobnumber":row.jobnumber},
                        }) .then(res=>{
                    this.$axios.get("/s/backend/api/user_list", {
                            params: { 'accessToken':localStorage.getItem('token'),
                        page :1,
                        limit :20,
                        roleid :8  }
                            })

                        .then(res=>{
                        
                            this.tableData = res.data.data
                    
                            })
                            .catch(function(){
                            
                            })
                            if(res.data.code==0){

                            }else{
                        this.$message({
                   message:res.data.msg,
                  type: 'success'
                     });
                            }
                                
                        })
        .catch(function(){
           
        })  
          },
             handleEdit15(index,row){
                  this.$axios({
                        method: "post",
                        url: "/s/backend/api/userban",
                        params: {
                        accessToken : localStorage.getItem('token')
                        },
                        data:{ "flag": 0,
                         "jobnumber":row.jobnumber},
                        }) .then(res=>{
                    this.$axios.get("/s/backend/api/user_list", {
                            params: { 'accessToken':localStorage.getItem('token'),
                        page :1,
                        limit :20,
                        roleid :8  }
                            })

                        .then(res=>{
                        
                            this.tableData = res.data.data
                    
                            })
                            .catch(function(){
                            
                            })
                            if(res.data.code==0){

                            }else{
                        this.$message({
                   message:res.data.msg,
                  type: 'success'
                     });
                            }
                                
                        })
        .catch(function(){
           
        })  
          },
       },
        mounted(){

               this.$axios({
                                        method: 'post',
                                        url: '/s/backend/api/deleteuser',
                                          params: {
                        accessToken:localStorage.getItem('token'),
                   
                        },
                                        data:{
                                              "jobnumber": 20355

                                        },
                                    
                                    })
                                    .then(res => {
                                      
                                       
                                    })
                                    .catch(err => {
                                      
                                    })

this.$axios.get("/s/backend/message/get_message_unread", {
　  　params: { 'accessToken':localStorage.getItem('token')}
})
  .then(res=>{
         this.result = res.data.result
          
        })
        .catch(function(){
           
        })
  



           
this.$axios.get("/s/backend/arealist")

     .then(res=>{
    
       
        this.options = res.data.data
 
        })
        .catch(function(){
           
        })
            //选择职位
this.$axios.get("/s/backend/api/user_list", {
        params: { 'accessToken':localStorage.getItem('token'),
       page :1,
       limit :10,
       roleid :2  }
        })

     .then(res=>{
    
        this.tableData = res.data.data
        this.pagination.total = res.data.count
        this.jobnumber
        })
        .catch(function(){
           
        })

  

      
      
      this.$axios.get("/s/backend/api/user_detail", {
        params: { 'accessToken':localStorage.getItem('token'),
        'jobnumber':localStorage.getItem('jobnumber')
        }
        })

     .then(res=>{
          this.info = res.data.data
           
        })
        .catch(function(){
           
        })
        }
}
</script>

<style scoped>
.red{
    color: red;
}
/deep/.el-dialog__wrapper{
    top:-50px
}

/deep/.kk .el-dialog{
        width: 25%;

    float: right;
  
}





.actives{
    background: #0034FF  !important;
    color: #fff  !important ;
}

.item li:hover{
    color: #0034FF;
}
.item li a:hover{
    color: #0034FF !important;
}
   /deep/.el-table th > .cell {
      text-align: center;

    }

    /deep/.el-table .cell {
      text-align: center;

    }

/deep/.el-tabs--card>.el-tabs__header{
    border-bottom: none !important;
}

/deep/ .el-tabs--card>.el-tabs__header .el-tabs__item{
    border-bottom:1px solid #E4E7ED;}
/deep/ .el-tabs__item.is-active{
    border:1px solid #409EFF;
    border-left:1px solid #409EFF !important;
      border-bottom:1px solid #409EFF !important;
}
    .header{
            min-height: 57px;
            border-bottom: 1px solid #f5f5f5;
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
    }
    .header .left_menu{
        display: flex;   width: 100%;  padding:0 40px;
        justify-content: space-between;
    }
    .header .left_menu .item{
        display: flex;
        align-items: center;
    }
        .header .left_menu .item .a{
            margin-right: 60px;  
            cursor: pointer;
        }
           .header .left_menu .item li a{
               text-decoration: none;color: #000;font-size: 16px;
font-weight: 500;
           }

        .header .active{
            color: #0034FF;
            position:relative;
        }
             .header .active::before{
                      content: "";
   
         left: 0;
    position: absolute;
    bottom:-130%;
    height:3px;
    width:30px;
    background: #0034FF;
            }
            /deep/.el-radio-button__inner{
                padding:8px 15px;
                border-radius: 0;
            }
            /deep/.el-radio-button__orig-radio:checked+.el-radio-button__inner{


                background: #0034FF;
            }

            .list{
                display: flex;
            }
            .list p {
                padding:8px 15px;
               background: #FFFFFF;font-size: 14px;
border-radius: 4px 0px 0px 0px; cursor: pointer;
            }
            
   .rz-picter .img-avatar {
        width: 100px;
        height: 100px;
        border: 1px solid #000;
    }
 
/deep/.he .el-dialog{
        width: 60% !important;

  height: 80%;
  overflow-y: scroll;
  
}

/deep/.cj .el-dialog{
        width: 35% !important;

  height: 80%;
  overflow-y: scroll;
  
}
</style>